import React, { useState, useMemo, useRef } from "react";

import Select from "../Select";
import useOutside from "../../useOutside";
import RootPortal from "../../RootPortal";
import { usePopper } from "react-popper";
import { MoreOutlined } from "@ant-design/icons";
import theme from "../../Theme";
import { TableHeaderItem, TableHeaderItemDrop } from "./StyledWrapper";

const ColumnSelector = ({ allColumns, hiddenColumns, onChange }) => {
  const selectorRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isVisible, setVisibility] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
      {
        name: "preventOverflow",
      },
    ],
  });

  useOutside(selectorRef, () => setVisibility(false));

  const toggleMenu = (e) => {
    e.stopPropagation();
    setVisibility(!isVisible);
  };

  const options = useMemo(() => {
    if (!allColumns || !allColumns.length) {
      return [];
    }

    return allColumns.map((singleCol) => ({
      value: singleCol.accessor,
      label: singleCol.header ? singleCol.header : singleCol.accessor,
    }));
  }, [allColumns]);

  const transformHidden = useMemo(() => {
    if (!hiddenColumns || !hiddenColumns.length) {
      return options;
    }

    return options.filter(({ value }) => hiddenColumns.indexOf(value) === -1);
  }, [options, hiddenColumns]);

  const handleChange = (vals) => {
    if (vals && vals.length) {
      let accessors = vals.map(({ value }) => value);

      onChange(
        options
          .filter(({ value }) => accessors.indexOf(value) === -1)
          .map(({ value }) => value)
      );
    }
  };

  return (
    <TableHeaderItem onClick={(e) => e.stopPropagation()}>
      <MoreOutlined onClick={toggleMenu} ref={setReferenceElement} />
      {isVisible && (
        <RootPortal>
          <div ref={selectorRef}>
            <TableHeaderItemDrop
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <Select
                value={transformHidden}
                placeholder="Select columns.."
                options={options}
                onChange={handleChange}
                components={{ DropdownIndicator: () => null }}
                isMulti
                menuIsOpen={isVisible}
                isClearable={false}
                menuShouldBlockScroll={false}
                wrapperOptions={{
                  $width: "200px",
                  $spacing: {
                    mb: "0",
                  },
                  bgColor: "#fff",
                  borderColor: theme.colors.boxShadow,
                }}
              />
            </TableHeaderItemDrop>
          </div>
        </RootPortal>
      )}
    </TableHeaderItem>
  );
};

export default ColumnSelector;
