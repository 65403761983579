import {
  OPEN_USER_CREATION,
  CLOSE_USER_CREATION,
  OPEN_CONFIRMATOR,
  UPDATE_CONFIRMATOR,
  CLOSE_CONFIRMATOR,
} from "../types";

const initialState = {
  confirmator: {
    visible: false,
    data: null,
  },
  users: {
    visible: false,
    data: null,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_CONFIRMATOR: {
      return {
        ...state,
        confirmator: {
          visible: payload && Object.keys(payload).length,
          data:
            payload && Object.keys(payload).length
              ? {
                  ...payload,
                }
              : null,
        },
      };
    }
    case UPDATE_CONFIRMATOR: {
      return {
        ...state,
        confirmator: {
          ...state.confirmator,
          data: state.confirmator.visible
            ? {
                ...state.confirmator.data,
                ...payload,
              }
            : null,
        },
      };
    }
    case CLOSE_CONFIRMATOR: {
      return {
        ...state,
        confirmator: {
          ...initialState.confirmator,
        },
      };
    }

    case OPEN_USER_CREATION: {
      let data = null;
      if (payload && Object.keys(payload).length) {
        data = {
          ...payload,
        };
      }
      return {
        ...state,
        users: {
          visible: true,
          data,
        },
      };
    }
    case CLOSE_USER_CREATION: {
      return {
        ...state,
        users: {
          visible: false,
          data: null,
        },
      };
    }

    default:
      return state;
  }
}
