import {
  OPEN_USER_CREATION,
  CLOSE_USER_CREATION,
  OPEN_CONFIRMATOR,
  UPDATE_CONFIRMATOR,
  CLOSE_CONFIRMATOR,
} from "../types";

export const openConfirmatorAction = (data) => ({
  type: OPEN_CONFIRMATOR,
  payload: data,
});

export const updateConfirmatorAction = (data) => ({
  type: UPDATE_CONFIRMATOR,
  payload: data,
});

export const closeConfirmatorAction = () => ({
  type: CLOSE_CONFIRMATOR,
});

export const openUserCreationAction = (data) => ({
  type: OPEN_USER_CREATION,
  payload: data,
});

export const closeUserCreationAction = () => ({
  type: CLOSE_USER_CREATION,
});
