import { combineReducers } from "redux";
import single from "./single";
import all from "./all";
import statusTypes from "./statusTypes";

export default combineReducers({
  all,
  single,
  statusTypes,
});
