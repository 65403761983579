import React from "react";
import { Typography } from "antd";

import PropTypes from "prop-types";
import StyledWrapper, {
  StyledContent,
  StyledHeader,
  StyledCloser,
  StyledFooter,
} from "./StyledWrapper";

const { Title } = Typography;

const Modal = (props) => {
  return (
    <StyledWrapper style={props.style ? { ...props.style } : undefined}>
      <StyledContent
        $spacing={{
          p: "25px 25px 35px",
        }}
        {...props.contentOptions}
      >
        {props.title || props.closeHandler ? (
          <StyledHeader>
            {props.closeHandler ? (
              <StyledCloser onClick={props.closeHandler}>
                <span>&times;</span>
              </StyledCloser>
            ) : null}
            {props.title ? <Title level={3}>{props.title}</Title> : null}
          </StyledHeader>
        ) : null}
        {props.body ? (
          <div className="custom-modal__body">{props.body}</div>
        ) : null}
        {props.footer ? <StyledFooter>{props.footer}</StyledFooter> : null}
      </StyledContent>
    </StyledWrapper>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  closeHandler: PropTypes.func,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  footer: PropTypes.object,
};

export default Modal;
