import styled from "styled-components";
import { spacing } from "../../Theme/styledOptions";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  ${spacing}

  .user-avatar-text {
    &__image {
      height: ${(props) =>
        props.$imageHeight
          ? props.$imageHeight
          : props.$isSmall
          ? "25px"
          : "40px"};
      width: ${(props) =>
        props.$imageWidth
          ? props.$imageWidth
          : props.$isSmall
          ? "25px"
          : "40px"};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: ${(props) => props.theme.colors.primary};
      color: #fff;
      border-radius: 50%;
      margin-right: 7px;
      flex-shrink: 0;
      text-transform: uppercase;
      font-size: ${(props) => (props.$isSmall ? "10px" : "14px")};
    }

    &__info {
      margin-right: 7px;

      h6 {
        font-size: 14px;
        color: #000;
        margin: 0;
      }
    }

    &__email {
      color: #707070;
      font-size: 12px;
    }
  }
`;

export default StyledWrapper;
