import {
  FETCH_ALL_ROLES,
  UPDATE_SINGLE_ROLE,
  DELETE_SINGLE_ROLE,
} from "../../types";

const initialState = {
  params: null,
  data: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ALL_ROLES: {
      return {
        params: payload.params
          ? {
              ...payload.params,
            }
          : null,
        data: payload.data && payload.data.length ? [...payload.data] : [],
      };
    }
    case UPDATE_SINGLE_ROLE: {
      let newData = [...state.data],
        idx = newData.findIndex(
          (singleEl) => singleEl.roleId === payload.roleId
        );

      if (idx > -1) {
        newData.splice(idx, 1, {
          ...payload,
        });
      }
      return {
        ...state,
        data: newData,
      };
    }
    case DELETE_SINGLE_ROLE: {
      let newData = [...state.data],
        idx = newData.findIndex((singleEl) => singleEl.roleId === payload);

      if (idx > -1) {
        newData.splice(idx, 1);
      }
      return {
        ...state,
        data: newData,
      };
    }
    default:
      return state;
  }
}
