import styled from "styled-components";
import { flex, textAlignment, spacing } from "../../Theme/styledOptions";

const StyledWrapper = styled.label`
  padding: 0;
  max-width: 100%;
  margin: 0 0 5px 7px;
  display: block;
  font-size: 12px;
  text-transform: none;
  color: ${(props) => props.theme.colors.primary};
  ${flex}
  ${textAlignment}
  ${spacing}

  > span {
    color: ${(props) => props.theme.colors.warning};
    margin-left: 5px;
  }
`;

export default StyledWrapper;
