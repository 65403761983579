import { OPEN_CLASS_CODE_MODAL, CLOSE_CLASS_CODE_MODAL } from "../../types";

const initialState = false;

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case OPEN_CLASS_CODE_MODAL: {
      return true;
    }
    case CLOSE_CLASS_CODE_MODAL: {
      return false;
    }
    default:
      return state;
  }
}
