import styled from "styled-components";
import { spacing } from "../../Theme/styledOptions";

const StyledWrapper = styled.div`
  padding: 50px;
  text-align: center;
  ${spacing};
`;

export default StyledWrapper;
