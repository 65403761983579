const initialState = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 2,
    label: "Inactive",
  },
  {
    value: 7,
    label: "Suspended",
  },
];

export default function (state = initialState) {
  return state;
}
