import {
  FETCH_CLASSES,
  UPDATE_SINGLE_CLASS,
  DELETE_SINGLE_CLASS,
} from "../../types";

const initialState = {
  params: null,
  data: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CLASSES: {
      return {
        params: payload.params
          ? {
              ...payload.params,
            }
          : null,
        data: payload.data && payload.data.length ? [...payload.data] : [],
      };
    }
    case UPDATE_SINGLE_CLASS: {
      let newData = [...state.data],
        idx = newData.findIndex((singleEl) => singleEl.id === payload.id);

      if (idx > -1) {
        newData.splice(idx, 1, {
          ...newData[idx],
          ...payload,
          photo: payload.photo
            ? payload.photo.length
              ? payload.photo[0].base64
                ? payload.photo[0].base64
                : payload.photo
              : null
            : newData[idx].photo,
        });
      }
      return {
        ...state,
        data: newData,
      };
    }
    case DELETE_SINGLE_CLASS: {
      let newData = [...state.data],
        idx = newData.findIndex((singleEl) => singleEl.id === payload);

      if (idx > -1) {
        newData.splice(idx, 1);
      }
      return {
        ...state,
        data: newData,
      };
    }
    default:
      return state;
  }
}
