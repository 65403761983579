import styled from "styled-components";
import { flex, spacing } from "../../Theme/styledOptions";
import { lighten, darken } from "polished";

export const Text = styled.span`
  flex-grow: ${(props) => (props.$isConnection ? "1" : "0")};

  ${(props) => props.theme.breakpoints.down("sm")} {
    display: ${(props) => (props.$hideTextMobile ? "none" : "")};
  }
`;

const StyledWrapper = styled.button`
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: ${(props) =>
    props.$height
      ? props.$height
      : props.$size
      ? props.theme.heights[props.$size]
        ? props.theme.heights[props.$size]
        : props.$size
      : props.theme.heights.middle};
  width: ${(props) =>
    props.$width ? props.$width : props.$isBlock ? "100%" : "auto"};
  border: 1px solid
    ${(props) =>
      props.$isDisabled
        ? props.theme.colors.disabled
        : props.$borderColor
        ? props.$borderColor
        : props.$type === "primary"
        ? props.theme.colors.primary
        : props.$type === "light"
        ? props.theme.colors.primaryLightest
        : props.$type === "warning"
        ? props.theme.colors.warning
        : props.theme.colors.primary};
  border-radius: ${(props) =>
    props.$isFlat ? "0" : props.$isRounded ? "50%" : props.theme.borderRadius};
  color: ${(props) =>
    props.$isDisabled
      ? darken(0.3, props.theme.colors.disabled)
      : props.$color
      ? props.$color
      : props.$type === "primary" || props.$type === "warning"
      ? "#ffffff"
      : props.theme.colors.primary};
  padding: 6px 15px;
  font-size: 14px;
  background-color: ${(props) =>
    props.$isDisabled
      ? props.theme.colors.disabled
      : props.$bgColor
      ? props.$bgColor
      : props.$type === "primary"
      ? props.theme.colors.primary
      : props.$type === "light"
      ? props.theme.colors.primaryLightest
      : props.$type === "warning"
      ? props.theme.colors.warning
      : "#ffffff"};
  cursor: pointer;
  outline: none;
  appearance: none;
  transition: all 0.3s ease;
  ${spacing};
  ${flex};

  &:hover,
  &:active,
  &:focus {
    color: ${(props) =>
      props.$isDisabled
        ? darken(0.3, props.theme.colors.disabled)
        : props.$hoverColor
        ? props.$hoverColor
        : props.$type === "primary" ||
          props.$type === "light" ||
          props.$type === "warning"
        ? "#ffffff"
        : props.theme.colors.primary};
    background-color: ${(props) =>
      props.$isDisabled
        ? props.theme.colors.disabled
        : props.$hoverBgColor
        ? props.$hoverBgColor
        : props.$type === "primary"
        ? lighten(0.1, props.theme.colors.primary)
        : props.$type === "light"
        ? props.theme.colors.primary
        : props.$type === "warning"
        ? lighten(0.1, props.theme.colors.warning)
        : props.theme.colors.primaryLightest};
    border-color: ${(props) =>
      props.$isDisabled
        ? props.theme.colors.disabled
        : props.$hoverBorderColor
        ? props.$hoverBorderColor
        : props.$type === "primary"
        ? lighten(0.1, props.theme.colors.primary)
        : props.$type === "warning"
        ? lighten(0.1, props.theme.colors.warning)
        : props.theme.colors.primary};
  }

  > span {
    line-height: 1;

    + span {
      margin-left: 7px;
    }
  }

  &:disabled {
    opacity: ${(props) => (props.$isDisabled ? "1" : "0.5")};
    cursor: not-allowed;
  }

  .anticon {
    font-size: calc(
      ${(props) =>
          props.$size
            ? props.theme.heights[props.$size]
              ? props.theme.heights[props.$size]
              : props.$size
            : props.theme.heights.middle} / 2
    );
  }

  svg {
    fill: currentColor;
  }

  img,
  svg {
    display: block;
    width: calc(
      ${(props) =>
          props.$size
            ? props.theme.heights[props.$size]
              ? props.theme.heights[props.$size]
              : props.$size
            : props.theme.heights.middle} / 2
    );
    max-height: calc(
      ${(props) =>
          props.$size
            ? props.theme.heights[props.$size]
              ? props.theme.heights[props.$size]
              : props.$size
            : props.theme.heights.middle} / 2
    );
  }
`;

export default StyledWrapper;
