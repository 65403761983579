import { USER_LOGIN, USER_LOGOUT } from "../types";

import axios from "axios";
import setAxiosTokenHeaders from "../../utils/setAxiosTokenHeaders";
import moment from "moment";
import RoleCheck from "../../components/RoleCheck";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "max-age=0, no-cache",
  },
};

export const reinitializeIntercom = (user) =>
  user
    ? RoleCheck({
        user: user,
        permissions: [
          {
            key: "viewSchoolLiveChat",
            isBoolean: true,
          },
        ],
        schoolId: user.defaultSchool,
        disId: user.defaultDistrict,
        disAdminYes: true,
        yes: () => {
          let disUser = null;
          if (user.defaultDistrict && user.districts && user.districts.length) {
            disUser = user.districts.find(
              (singleDis) => singleDis.districtId === user.defaultDistrict
            );
          }

          window.Intercom("shutdown");
          window.Intercom("boot", {
            app_id: "qo9sn0m0",
            user_id: user.id,
            name: `${user.firstName ? user.firstName : ""}${
              user.lastName ? " " + user.lastName : ""
            }`,
            email: user.email,
            phone: user.phone ? user.phone : "N/A",
            company: {
              id: user.defaultDistrict,
              name:
                disUser && disUser.districtName ? disUser.districtName : "N/A",
            },
            created_at: moment(user.createdAt, "MM/DD/YYYY HH:mm").unix(),
          });
        },
        no: () => window.Intercom("shutdown"),
      })
    : null;

export const rebootChatAction = () => {
  window.Intercom("shutdown");
  window.Intercom("boot", {
    app_id: "qo9sn0m0",
  });
};

export const authToken = (token, isInit) => async (dispatch) => {
  try {
    setAxiosTokenHeaders(token);
    const res = await axios.get("/users/me", config);
    reinitializeIntercom(res.data);

    dispatch({
      type: USER_LOGIN,
      payload: {
        token,
        user: {
          ...res.data,
        },
      },
    });
  } catch (err) {
    if (!isInit) {
      if (err.response) {
        throw new Error("Could not sign user in. Please try again later..");
      } else {
        throw new Error(
          "Network error - Please check your network connection or firewall settings and try again."
        );
      }
    }
  }
};

export const userLoginAction = (data) => async (dispatch) => {
  const body = JSON.stringify(data);

  try {
    const res = await axios.post("/auth/login", body, config);

    if (!res.data.isTwoFA) {
      window.localStorage.setItem("practiceToken", res.data.token);
      setAxiosTokenHeaders(res.data.token);
      reinitializeIntercom(res.data.user);

      dispatch({
        type: USER_LOGIN,
        payload: res.data,
      });
    }

    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response) {
      throw new Error("Could not sign user in. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const userLoginGoogleAction =
  ({ districtId, schoolId, code }) =>
  async (dispatch) => {
    try {
      const { data } = await axios({
        url: `https://oauth2.googleapis.com/token`,
        method: "post",
        data: {
          client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT,
          client_secret: process.env.REACT_APP_GOOGLE_OAUTH_SECRET,
          redirect_uri: process.env.REACT_APP_GOOGLE_AUTH_PAGE,
          grant_type: "authorization_code",
          code,
        },
      });

      const googleData = await axios({
        url: "https://www.googleapis.com/oauth2/v2/userinfo",
        method: "get",
        headers: {
          Authorization: `Bearer ${data.access_token}`,
        },
      });

      const res = await axios.post(
        "/auth/google",
        {
          email: googleData.data.email,
          firstName: googleData.data.given_name,
          lastName: googleData.data.family_name,
          avatar: googleData.data.picture,
          id: googleData.data.id,
          districtId,
          schoolId,
        },
        config
      );

      window.localStorage.setItem("practiceToken", res.data.token);
      setAxiosTokenHeaders(res.data.token);
      reinitializeIntercom(res.data.user);

      dispatch({
        type: USER_LOGIN,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      console.log(err);
      if (err.response) {
        throw new Error("Could not sign user in. Please try again later..");
      } else {
        throw new Error(
          "Network error - Please check your network connection or firewall settings and try again."
        );
      }
    }
  };

// Clever

export const getLoginLinkCleverAction = async (disId) => {
  try {
    const res = await axios.get(`/auth/clever?districtId=${disId}`, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error occurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const userLoginCleverAction = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/auth/clever", JSON.stringify(data), config);

    window.localStorage.setItem("practiceToken", res.data.token);
    setAxiosTokenHeaders(res.data.token);
    reinitializeIntercom(res.data.user);

    dispatch({
      type: USER_LOGIN,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error occurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const userLogoutAction = () => (dispatch) => {
  localStorage.removeItem("practiceToken");
  rebootChatAction();
  dispatch({
    type: USER_LOGOUT,
  });
};

export const userRegisterAction = (data) => async (dispatch) => {
  const body = JSON.stringify({ ...data });
  try {
    const res = await axios.post("/auth/register", body, config);
    setAxiosTokenHeaders(res.data.token);
    reinitializeIntercom(res.data.user);

    dispatch({
      type: USER_LOGIN,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        throw new Error("Email is already in use.");
      } else if (err.response.status === 422) {
        throw new Error(
          "Some of the fields are incorrect. Please check again.."
        );
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// User Invite
export const userInviteAction = async (data) => {
  const body = JSON.stringify({ ...data });
  try {
    await axios.post("/auth/invite", body, config);
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        throw new Error("Email is already in use.");
      } else if (err.response.status === 422) {
        throw new Error(
          "Some of the fields are incorrect. Please check again.."
        );
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const userInviteDecryptAction = async (code) => {
  const body = JSON.stringify({ code });
  try {
    const res = await axios.post("/auth/decrypt", body, config);
    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        throw new Error("Email is already in use.");
      } else if (err.response.status === 422) {
        throw new Error(
          "Some of the fields are incorrect. Please check again.."
        );
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Verify Class Code

export const verifyClassCodeAction = async (inviteCode) => {
  const body = JSON.stringify({ inviteCode });
  try {
    const res = await axios.post("/auth/verify-class-code", body, config);
    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        throw new Error("Invalid code.");
      } else {
        throw new Error("An error ocurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Forgot Password
export const forgotPasswordAction = async (data) => {
  const body = JSON.stringify({ ...data });
  try {
    await axios.post("/auth/forgot-password", body, config);
  } catch (err) {
    if (err.response) {
      throw new Error("An error occurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const resetPasswordAction = (token, password) => async (dispatch) => {
  const body = JSON.stringify({ token, password });
  try {
    const res = await axios.post("/auth/reset-password", body, config);

    window.localStorage.setItem("practiceToken", res.data.token);
    setAxiosTokenHeaders(res.data.token);
    reinitializeIntercom(res.data.user);

    dispatch({
      type: USER_LOGIN,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        throw new Error("Token expired. Please request new password reset.");
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Verify Two Factor

export const userVerifyAction = (data) => async (dispatch) => {
  const body = JSON.stringify({ ...data });

  try {
    const res = await axios.post("/auth/verify", body, config);

    window.localStorage.setItem("practiceToken", res.data.token);
    setAxiosTokenHeaders(res.data.token);
    reinitializeIntercom(res.data.user);

    dispatch({
      type: USER_LOGIN,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("Could not sign user in. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const userResendVerificationAction = async (data) => {
  const body = JSON.stringify({ ...data });

  try {
    const res = await axios.post("/auth/2fa", body, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("Could not resend the code. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};
