import React, { useState, useEffect, useCallback, Fragment } from "react";
import { connect } from "react-redux";

import {
  fetchUsersAction,
  createUserAction,
  validateUsersAction,
  uploadUsersCSVAction,
} from "../../redux/actions/Users";
import { closeUserCreationAction } from "../../redux/actions/links";
import { fetchRolesAction } from "../../redux/actions/Roles";
import { fetchSchoolsAction } from "../../redux/actions/Schools";
import { fetchAllDistrictsAction } from "../../redux/actions/Districts";
import {
  fetchDisClassesAction,
  fetchClassesAction,
} from "../../redux/actions/Classes";

import Modal from "../UI/Modal";
import Spinner from "../UI/Spinner";
import HorizontalSpace from "../UI/HorizontalSpace";
import Stepper from "../UI/Stepper";

import TypeStep from "./TypeStep";
import AddStep from "./AddStep";
import ConfirmStep from "./ConfirmStep";
import OverviewStep from "./OverviewStep";
import RoleCheck from "../RoleCheck";

import { message, Button } from "antd";
import isEqual from "lodash.isequal";

const CreateUserModal = ({
  schools,
  roles,
  classes,
  user,
  data,
  fetchSchoolsAction,
  fetchRolesAction,
  fetchUsersAction,
  closeUserCreationAction,
  createUserAction,
  fetchAllDistrictsAction,
  fetchDisClassesAction,
  fetchClassesAction,
}) => {
  const [cmpState, editState] = useState({
    tableData: [],
    emails: [],
    skippedUsers: [],
    uploadedUsers: [],
    csv: null,
    type: "manual", // csv
    activeStep: "type", // add, confirm, overview
    isGA: false,
    isDA: false,
    addDisUsers: false,
    isDistrict: false,
    districtId: null,
    schoolId: null,
    allRoles: [],
    allSchools: [],
    allClasses: [],
    emailsChanged: false,
    emailsError: false,
    csvChanged: false,
    csvError: false,
    isLoaded: false,
  });
  const [isSaving, setSaving] = useState(false);

  const {
    tableData,
    emails,
    skippedUsers,
    uploadedUsers,
    csv,
    type,
    activeStep,
    isGA,
    isDA,
    addDisUsers,
    isDistrict,
    districtId,
    schoolId,
    allRoles,
    allSchools,
    allClasses,
    emailsChanged,
    emailsError,
    csvChanged,
    csvError,
    isLoaded,
  } = cmpState;

  useEffect(
    () => {
      let isDistrict = data && data.isDistrict ? true : false,
        districtId =
          data && data.districtId ? data.districtId : user.defaultDistrict,
        schoolId = data && data.schoolId ? data.schoolId : user.defaultSchool;

      const isGA = user.globalAdmin && isDistrict,
        isDA = isDistrict
          ? RoleCheck({
              user: user,
              disId: districtId,
              disAdminYes: true,
              yes: () => true,
              no: () => false,
            })
          : false,
        addDisUsers = isDistrict
          ? RoleCheck({
              user: user,
              permissions: [
                {
                  key: "addDistrictUsers",
                  isBoolean: true,
                },
              ],
              disId: districtId,
              yes: () => true,
              no: () => false,
            })
          : false;

      async function initData() {
        try {
          let allRoles = [],
            allSchools = [],
            allClasses = [];

          if (
            !isEqual(schools.params, {
              districtId,
            })
          ) {
            allSchools = await fetchSchoolsAction({
              districtId,
            });
          } else {
            allSchools = schools.data;
          }

          if (
            !isEqual(roles.params, {
              districtId: isDistrict ? districtId : undefined,
              schoolId: isDistrict ? undefined : schoolId,
            })
          ) {
            allRoles = await fetchRolesAction({
              districtId: isDistrict ? districtId : undefined,
              schoolId: isDistrict ? undefined : schoolId,
            });
          } else {
            allRoles = roles.data;
          }

          if (
            !classes.params ||
            (isDistrict
              ? !classes.params.districtId ||
                parseInt(classes.params.districtId, 10) !==
                  parseInt(districtId, 10)
              : !classes.params.schoolId ||
                parseInt(classes.params.schoolId, 10) !==
                  parseInt(schoolId, 10))
          ) {
            if (isDistrict) {
              allClasses = await fetchDisClassesAction(districtId);
            } else {
              allClasses = await fetchClassesAction(schoolId);
            }
          } else {
            allClasses = classes.data;
          }

          editState((prevState) => ({
            ...prevState,
            isGA,
            isDA,
            isDistrict,
            addDisUsers,
            districtId,
            schoolId,
            allRoles,
            allSchools,
            allClasses,
            isLoaded: true,
          }));
        } catch (err) {
          console.log(err.message);
          message.warning("An error ocurred while fetching users or roles.");
        }
      }

      initData();
    },
    // eslint-disable-next-line
    [data, user]
  );

  const transformUser = useCallback(
    (data) =>
      data && data.length
        ? data
            .map((singleVal) => {
              const overallCheck =
                singleVal.inDistrict ||
                singleVal.inSchool ||
                (singleVal.error &&
                  (singleVal.error.user || singleVal.error.email));

              return {
                ...singleVal,
                firstName: singleVal.firstName ? singleVal.firstName : "",
                firstNameError: overallCheck ? false : !singleVal.firstName,
                lastName: singleVal.lastName ? singleVal.lastName : "",
                lastNameError: overallCheck ? false : !singleVal.lastName,
                schoolId: singleVal.schoolId ? singleVal.schoolId : null,
                schoolIdError:
                  singleVal.error &&
                  singleVal.error.school &&
                  !singleVal.error.user &&
                  !singleVal.error.email
                    ? true
                    : false,
                roleId: singleVal.roleId ? singleVal.roleId : null,
                roleIdError:
                  singleVal.error &&
                  singleVal.error.role &&
                  !singleVal.error.user &&
                  !singleVal.error.email
                    ? true
                    : false,
                classId: singleVal.classId ? singleVal.classId : null,
                classIdError:
                  singleVal.error &&
                  singleVal.error.class &&
                  !singleVal.error.user &&
                  !singleVal.error.email
                    ? true
                    : false,
                classRole: singleVal.classRole ? singleVal.classRole : null,
                classRoleError:
                  singleVal.error &&
                  singleVal.error.classRole &&
                  !singleVal.error.user &&
                  !singleVal.error.email
                    ? true
                    : false,
              };
            })
            .sort((a, b) => {
              const aErrors =
                  a.firstNameError ||
                  a.lastNameError ||
                  a.schoolIdError ||
                  a.roleIdError ||
                  a.classIdError ||
                  a.classRoleError ||
                  (a.error && (a.error.user || a.error.email)),
                bErrors =
                  b.firstNameError ||
                  b.lastNameError ||
                  b.schoolIdError ||
                  b.roleIdError ||
                  b.classIdError ||
                  b.classRoleError ||
                  (b.error && (b.error.user || b.error.email));

              if (aErrors) {
                return -1;
              }

              if (bErrors) {
                return 1;
              }

              return 0;
            })
        : [],
    []
  );

  const onStepChange = useCallback(
    async (step) => {
      if (
        !type &&
        (step === "add" || step === "confirm" || step === "overview")
      ) {
        message.warning("Please select the type first.");
        return;
      }

      let csvError = false,
        csvUploadError = false,
        csvUpdated = csvChanged,
        emailsError = false,
        emailsUploadError = false,
        emailsUpdated = emailsChanged,
        newTableData = [...tableData],
        basicData = {
          districtId: districtId,
        };

      if (!isDistrict) {
        basicData.schoolId = schoolId;
      }

      if (step === "confirm" || step === "overview") {
        if (type === "csv") {
          if (!csv) {
            csvError = true;
          } else if (csvUpdated) {
            try {
              setSaving(true);
              newTableData = await uploadUsersCSVAction({
                ...basicData,
                csv,
              });
              csvUpdated = false;

              newTableData = transformUser(newTableData);
              setSaving(false);
            } catch (err) {
              csvUploadError = true;
              console.log(err);
              message.warning(err.message);
              setSaving(false);
            }
          }
        } else {
          if (!emails.length) {
            emailsError = true;
          } else if (emailsUpdated) {
            try {
              setSaving(true);
              newTableData = await validateUsersAction({
                ...basicData,
                emails,
              });
              emailsUpdated = false;

              newTableData = transformUser(newTableData);
              setSaving(false);
            } catch (err) {
              emailsUploadError = true;
              console.log(err);
              message.warning(err.message);
              setSaving(false);
            }
          }
        }
      }

      let activeStep = step,
        skippedUsers = [],
        uploadedUsers = [];
      if (csvError || emailsError || csvUploadError || emailsUploadError) {
        activeStep = "add";
      } else if (step === "overview") {
        let hasError = false;
        newTableData = newTableData.map((singleData) => {
          const overallCheck =
              singleData.error &&
              (singleData.error.user || singleData.error.email),
            firstNameError =
              singleData.inDistrict || singleData.inSchool || overallCheck
                ? false
                : !singleData.firstName,
            lastNameError =
              singleData.inDistrict || singleData.inSchool || overallCheck
                ? false
                : !singleData.lastName,
            schoolIdError =
              isDistrict || overallCheck ? false : !singleData.schoolId,
            roleIdError = overallCheck
              ? false
              : singleData.schoolId && !singleData.roleId
              ? true
              : isDistrict
              ? false
              : !singleData.roleId,
            classRoleError = overallCheck
              ? false
              : singleData.classId && !singleData.classRole;

          if (
            firstNameError ||
            lastNameError ||
            schoolIdError ||
            roleIdError ||
            classRoleError
          ) {
            hasError = true;
          }

          return {
            ...singleData,
            firstNameError,
            lastNameError,
            schoolIdError,
            roleIdError,
            classRoleError,
          };
        });

        if (hasError) {
          activeStep = "confirm";
        } else {
          try {
            let newData = [];

            for (let singleData of newTableData) {
              if (
                (singleData.error &&
                  (singleData.error.user || singleData.error.email)) ||
                (isDistrict && singleData.inDistrict && !singleData.schoolId)
              ) {
                skippedUsers.push(singleData);
              } else {
                let userObj = {
                  id: singleData.id,
                  email: singleData.email,
                  firstName: singleData.firstName,
                  lastName: singleData.lastName,
                  districtId: singleData.districtId,
                  permission:
                    singleData.permission && singleData.permission.length
                      ? [...singleData.permission]
                      : [],
                };

                let newPassword = singleData.password
                  ? singleData.password.trim()
                  : "";

                if (newPassword) {
                  userObj.password = newPassword;
                }

                if (singleData.schoolId) {
                  let schoolIdx = userObj.permission.findIndex(
                    ({ schoolId }) =>
                      parseInt(schoolId, 10) ===
                      parseInt(singleData.schoolId, 10)
                  );

                  let newPermission = {
                    districtId: singleData.districtId,
                    schoolId: singleData.schoolId,
                    roleId: singleData.roleId,
                  };

                  if (schoolIdx > -1) {
                    userObj.permission.splice(schoolIdx, 1, {
                      ...newPermission,
                    });
                  } else {
                    userObj.permission.push({
                      ...newPermission,
                    });
                  }
                }

                if (singleData.classId && singleData.classRole) {
                  userObj.classId = singleData.classId;
                  userObj.classRole = singleData.classRole;
                }

                newData.push(userObj);
              }
            }

            if (newData.length) {
              setSaving(true);
              uploadedUsers = await createUserAction(newData);
              setSaving(false);

              try {
                await fetchUsersAction({
                  districtId: isDistrict ? districtId : undefined,
                  schoolId: isDistrict ? undefined : schoolId,
                });
              } catch (err) {
                console.log(err);
                message.warning(
                  "We had an error fetching new user list. You might need to refresh."
                );
              }

              if (isDistrict) {
                try {
                  await fetchAllDistrictsAction();
                } catch (err) {
                  console.log(err);
                  message.warning(
                    "We had an error updating all districts list. You might need to refresh."
                  );
                }
              }
            }
          } catch (err) {
            activeStep = "confirm";
            console.log(err);
            message.warning(err.message);
            setSaving(false);
          }
        }
      }

      editState((prevState) => ({
        ...prevState,
        tableData: newTableData,
        skippedUsers,
        uploadedUsers,
        activeStep,
        csvChanged: csvUpdated,
        csvError,
        emailsChanged: emailsUpdated,
        emailsError,
      }));
    },
    // eslint-disable-next-line
    [
      type,
      csv,
      emails,
      emailsChanged,
      csvChanged,
      tableData,
      isDistrict,
      districtId,
      schoolId,
      transformUser,
    ]
  );

  const onTypeChange = useCallback((name, value) => {
    editState((prevState) => ({
      ...prevState,
      type: value,
    }));
  }, []);

  const onEmailChange = useCallback((emails) => {
    editState((prevState) => ({
      ...prevState,
      emails,
      emailsChanged: true,
      tableData: [],
    }));
  }, []);

  const onCSVChange = useCallback((csv) => {
    editState((prevState) => ({
      ...prevState,
      csv,
      csvChanged: true,
      tableData: [],
    }));
  }, []);

  const onDataChange = useCallback(
    (field, val, email) => {
      let newData = tableData.map((singleData) => {
        let obj = {
          ...singleData,
        };

        if (singleData.email === email) {
          obj[field] = val;

          if (field !== "password" && field !== "classId") {
            obj[`${field}Error`] = !val
              ? field === "firstName" ||
                field === "lastName" ||
                field === "classRole" ||
                !isDistrict
              : false;
          }

          if (field === "schoolId") {
            let newRoleId = null;

            if (singleData && singleData.permission) {
              let currentRole = singleData.permission.find(
                (singlePer) =>
                  parseInt(singlePer.schoolId, 10) === parseInt(val, 10)
              );

              if (currentRole && currentRole.roleId) {
                newRoleId = currentRole.roleId;
              }
            }

            obj.roleId = newRoleId;
            obj.roleIdError = false;
            obj.classId = null;
            obj.classIdError = false;
            obj.classRole = null;
            obj.classRoleError = false;
          }

          if (field === "classId") {
            obj.classIdError = false;
            obj.classRole = null;
            obj.classRoleError = false;
          }
        }

        return obj;
      });

      editState((prevState) => ({
        ...prevState,
        tableData: newData,
      }));
    },
    [tableData, isDistrict]
  );

  return (
    <Modal
      title="New User"
      body={
        isLoaded ? (
          <Fragment>
            <Stepper
              steps={[
                { key: "type", title: "Select Type" },
                { key: "add", title: "Add Users" },
                { key: "confirm", title: "Confirm" },
                { key: "overview", title: "Overview" },
              ]}
              current={activeStep}
              onChange={onStepChange}
              clickable
              listStyles={{
                $width: "70%",
              }}
            />

            {activeStep === "type" ? (
              <TypeStep onChange={onTypeChange} type={type} />
            ) : null}

            {activeStep === "add" ? (
              <AddStep
                emails={emails}
                emailsError={emailsError}
                csv={csv}
                csvError={csvError}
                onEmailChange={onEmailChange}
                onCSVChange={onCSVChange}
                type={type}
                isDistrict={isDistrict}
              />
            ) : null}

            {activeStep === "confirm" ? (
              <ConfirmStep
                data={tableData}
                onDataChange={onDataChange}
                allRoles={allRoles}
                allSchools={allSchools}
                allClasses={allClasses}
                isGA={isGA}
                isDA={isDA}
                addDisUsers={addDisUsers}
                isDistrict={isDistrict}
                schoolId={schoolId}
                user={user}
              />
            ) : null}

            {activeStep === "overview" ? (
              <OverviewStep
                skippedUsers={skippedUsers}
                uploadedUsers={uploadedUsers}
                isDistrict={isDistrict}
                districtId={districtId}
              />
            ) : null}
          </Fragment>
        ) : (
          <Spinner />
        )
      }
      footer={
        <HorizontalSpace>
          {activeStep !== "type" && activeStep !== "overview" ? (
            <Button
              id="user-creation-back"
              shape="round"
              htmlType="button"
              onClick={() =>
                onStepChange(activeStep === "add" ? "type" : "add")
              }
              loading={isSaving}
            >
              Back
            </Button>
          ) : null}

          {activeStep !== "overview" ? (
            <Button
              id="user-creation-next"
              type="primary"
              shape="round"
              htmlType="button"
              onClick={() =>
                onStepChange(
                  activeStep === "type"
                    ? "add"
                    : activeStep === "add"
                    ? "confirm"
                    : "overview"
                )
              }
              loading={isSaving}
            >
              Next
            </Button>
          ) : (
            <Button
              id="user-creation-finish"
              type="primary"
              shape="round"
              htmlType="button"
              onClick={() => closeUserCreationAction()}
              loading={isSaving}
            >
              Finish
            </Button>
          )}
        </HorizontalSpace>
      }
      closeHandler={() => closeUserCreationAction()}
      contentOptions={{
        $width: activeStep === "confirm" ? "1000px" : undefined,
      }}
    />
  );
};

export default connect(
  (state) => ({
    user: state.auth.user,
    data: state.links.users.data,
    schools: state.schools.all,
    roles: state.roles,
    classes: state.classes.all,
  }),
  {
    fetchDisClassesAction,
    fetchClassesAction,
    closeUserCreationAction,
    fetchRolesAction,
    fetchUsersAction,
    fetchSchoolsAction,
    createUserAction,
    fetchAllDistrictsAction,
  }
)(CreateUserModal);
