import React, { Component } from "react";
import NotAuthorized from "./UI/NotAuthorized";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      return (
        <div style={{ padding: "20px" }}>
          <NotAuthorized
            message={
              error && error.name === "ChunkLoadError"
                ? "This application has been updated, please refresh your browser to see the latest content."
                : "An error has occurred, please refresh and try again."
            }
          />
        </div>
      );
    }
    return this.props.children;
  }
}
