import { combineReducers } from "redux";
import announcements from "./announcements";
import messages from "./messages";
import visibility from "./visibility";

export default combineReducers({
  visibility,
  messages,
  announcements,
});
