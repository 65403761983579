import { FETCH_TAGS, DELETE_SINGLE_TAG } from "../../types";

const initialState = {
  params: null,
  data: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TAGS: {
      return {
        params: payload.params
          ? {
              ...payload.params,
            }
          : null,
        data: payload.data && payload.data.length ? [...payload.data] : [],
      };
    }
    case DELETE_SINGLE_TAG: {
      let newData = [...state.data],
        idx = newData.findIndex((singleEl) => singleEl.relationId === payload);

      if (idx > -1) {
        newData.splice(idx, 1);
      }
      return {
        ...state,
        data: newData,
      };
    }
    default:
      return state;
  }
}
