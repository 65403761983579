import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const body = document.body;

const RootPortal = (props) => {
  const [wrapper] = useState(document.createElement("div"));

  useEffect(() => {
    wrapper.setAttribute("class", "root-portal");
    if (props.customId) {
      wrapper.setAttribute("id", props.customId);
    }

    body.appendChild(wrapper);

    return () => body.removeChild(wrapper);

    // eslint-disable-next-line
  }, [props.customId]);

  return ReactDOM.createPortal(props.children, wrapper);
};

export default RootPortal;
