import React, { useEffect, useState, useMemo } from "react";

import Wrapper, { selectStyles, ErrorField } from "./StyledWrapper";
import MainLabel from "../Label";
import { default as RSelect, components } from "react-select";
import { CaretDownOutlined, LoadingOutlined } from "@ant-design/icons";

import PropTypes from "prop-types";

const dropdownIndicator = (isSaving) => (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      {isSaving ? <LoadingOutlined /> : <CaretDownOutlined />}
    </components.DropdownIndicator>
  );

const Select = ({
  wrapperOptions,
  label,
  errorMessage,
  isRequired,
  isError,
  isLoading,
  isPortal,
  styles,
  ...selectProps
}) => {
  const [rootBody, setBody] = useState(null);
  const allStyles = useMemo(() => {
    let styleTransform = selectStyles;

    if (styles) {
      styleTransform = {
        ...styleTransform,
        ...styles,
      };
    }

    return styleTransform;
  }, [styles]);

  useEffect(() => {
    setBody(document.body);
  }, []);

  return (
    <Wrapper
      $spacing={{
        mb: "12px",
      }}
      {...wrapperOptions}
    >
      {label ? <MainLabel text={label} isRequired={isRequired} /> : null}
      <RSelect
        components={{
          DropdownIndicator: dropdownIndicator(isLoading),
        }}
        menuPortalTarget={isPortal ? rootBody : undefined}
        styles={allStyles}
        menuShouldBlockScroll
        maxMenuHeight={150}
        menuPlacement="auto"
        classNamePrefix="custom-select-element"
        {...selectProps}
      />
      {isError ? (
        <ErrorField>
          {errorMessage ? errorMessage : "Field is required"}
        </ErrorField>
      ) : null}
    </Wrapper>
  );
};

Select.propTypes = {
  wrapperOptions: PropTypes.object,
  errorMessage: PropTypes.string,
  isRequired: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPortal: PropTypes.bool,
  styles: PropTypes.object,
};

export default Select;
