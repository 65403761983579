import styled from "styled-components";
import { flex, spacing } from "../../Theme/styledOptions";

const StyledWrapper = styled.div`
  ${flex};
  > * {
    ${spacing};
  }
`;

export default StyledWrapper;
