import React, { useMemo } from "react";
import { Fragment } from "react";
import CreatableSelect from "../../UI/CreatableSelect";
import Uploader from "../../UI/Uploader";

import schoolExample from "../../../assets/school-csv-example.csv";
import disExample from "../../../assets/dis-csv-example.csv";

const AddStep = ({
  emails,
  emailsError,
  csv,
  csvError,
  onEmailChange,
  onCSVChange,
  type,
  isDistrict,
}) => {
  const handleUser = (vals) => {
    let newUsers = [];

    if (vals && vals.length) {
      vals.forEach(({ value }) => {
        const currentEmails = value
          .split(/[\s,]/)
          .filter((singleMail) => singleMail);

        currentEmails.forEach((email) => {
          if (newUsers.indexOf(email) === -1) {
            newUsers.push(email);
          }
        });
      });
    }

    onEmailChange(newUsers);
  };

  const renderUserLabels = useMemo(
    () =>
      emails.map((singleUser) => ({
        value: singleUser,
        label: singleUser,
      })),
    [emails]
  );

  return (
    <Fragment>
      <p className="text-center">
        {type === "manual" ? (
          "Please write email addresses of the users you want to add."
        ) : (
          <Fragment>
            Please add your .csv file below. <br />
            For an example of how to format your spreadsheet, download our
            template below. <br />
            <a href={isDistrict ? disExample : schoolExample} download>
              Download Template
            </a>
          </Fragment>
        )}
      </p>
      {type === "manual" ? (
        <CreatableSelect
          id="user-creation-manual-uploader"
          inputId="user-creation-manual-uploader-input"
          value={renderUserLabels}
          placeholder="Insert emails separated by a comma"
          onChange={handleUser}
          options={[]}
          noOptionsMessage={() => "Insert emails serparated by a comma"}
          isPortal
          isMulti
          isError={emailsError}
          errorMessage="Please enter valid emails"
        />
      ) : (
        <Uploader
          id="user-creation-csv-uploader"
          value={csv}
          accept=".csv, text/csv"
          placeholderText={csv ? "CSV Selected" : "Select CSV"}
          onChange={onCSVChange}
          onDelete={() => onCSVChange(null)}
          isError={csvError}
          errorMessage="Please select valid CSV file"
          disableGDrive
        />
      )}
    </Fragment>
  );
};

export default AddStep;
