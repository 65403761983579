import React, { useState, useMemo, useRef } from "react";

import { usePopper } from "react-popper";
import useOutside from "../../useOutside";
import RootPortal from "../../RootPortal";
import Select from "../Select";
import { FilterOutlined } from "@ant-design/icons";
import theme from "../../Theme";

import { TableHeaderItem, TableHeaderItemDrop } from "./StyledWrapper";

const Filterer = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const filterRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isVisible, setVisibility] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
      {
        name: "preventOverflow",
      },
    ],
  });

  useOutside(filterRef, () => setVisibility(false));

  const toggleMenu = (e) => {
    e.stopPropagation();
    setVisibility(!isVisible);
  };

  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    let mapped = [...options.values()].map((singleVal) => ({
      value: singleVal,
      label: singleVal,
    }));

    return [
      {
        value: "",
        label: "All",
      },
      ...mapped,
    ];
  }, [id, preFilteredRows]);

  return (
    <TableHeaderItem onClick={(e) => e.stopPropagation()}>
      <FilterOutlined onClick={toggleMenu} ref={setReferenceElement} />
      {isVisible && (
        <RootPortal>
          <div ref={filterRef}>
            <TableHeaderItemDrop
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <Select
                value={
                  filterValue
                    ? options.find((opt) => opt.value === filterValue)
                    : null
                }
                placeholder="Filter by"
                options={options}
                onChange={({ value }) => {
                  setFilter(value || undefined);
                  setVisibility(false);
                }}
                components={{ DropdownIndicator: () => null }}
                menuIsOpen={isVisible}
                maxMenuHeight={150}
                wrapperOptions={{
                  $width: "200px",
                  $spacing: {
                    mb: "0",
                  },
                  bgColor: "#fff",
                  borderColor: theme.colors.boxShadow,
                }}
              />
            </TableHeaderItemDrop>
          </div>
        </RootPortal>
      )}
    </TableHeaderItem>
  );
};

export default Filterer;
