import React from "react";
import StyledWrapper from "./StyledWrapper";

const HorizontalSpace = (props) => {
  return (
    <StyledWrapper
      $spacing={{
        m: "0 8px",
      }}
      {...props}
    >
      {props.children}
    </StyledWrapper>
  );
};

export default HorizontalSpace;
