import {
  FETCH_ROLES_STRUCTURE,
  FETCH_CLASS_STRUCTURE,
  FETCH_DISTRICT_STRUCTURE,
} from "../types";

const initialState = {
  structureRole: [],
  structureClass: [],
  structureDistrict: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ROLES_STRUCTURE: {
      return {
        ...state,
        structureRole: [...payload],
      };
    }
    case FETCH_CLASS_STRUCTURE: {
      return {
        ...state,
        structureClass: [...payload],
      };
    }
    case FETCH_DISTRICT_STRUCTURE: {
      return {
        ...state,
        structureDistrict: [...payload],
      };
    }
    default:
      return state;
  }
}
