import React from "react";
import StyledWrapper, {
  StyledList,
  StyledListItem,
  StyledDot,
  StyledDotBg,
  StyledText,
} from "./StyledWrapper";

import PropTypes from "prop-types";

const Stepper = ({
  steps,
  current,
  onChange,
  clickable,
  listStyles,
  ...rest
}) => {
  const renderSteps = () =>
    steps
      ? steps.map((singleStep, idx) => (
          <StyledListItem
            key={singleStep.key}
            $clickable={clickable ? true : false}
            style={{
              left: ((idx / (steps.length - 1)) * 100).toFixed(2) + "%",
            }}
            onClick={() =>
              onChange && clickable ? onChange(singleStep.key) : null
            }
          >
            <StyledDotBg $active={singleStep.key === current ? true : false} />
            <StyledDot />
            <StyledText>
              {singleStep.title ? <span>{singleStep.title}</span> : null}
            </StyledText>
          </StyledListItem>
        ))
      : [];

  return (
    <StyledWrapper {...rest}>
      <StyledList {...listStyles}>{renderSteps()}</StyledList>
    </StyledWrapper>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  current: PropTypes.any,
  clickable: PropTypes.bool,
};

export default Stepper;
