import React, { useMemo, useEffect, useState } from "react";
import Select from "../../../UI/Select";
import Input from "../../../UI/Input";
import theme from "../../../Theme";

const CustomCell = ({
  value: initialValue,
  column: { id },
  row: {
    index,
    original: { email, schoolId, classId, ...rest },
  },
  customCellData: {
    schoolOpts,
    allRoles,
    allClasses,
    cellHandler,
    isGA,
    isDA,
    addDisUsers,
    user,
  },
}) => {
  const [cmpVal, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  const onBlur = () => {
    if (cellHandler) {
      cellHandler(id, cmpVal, email, index);
    }
  };

  const onSelectChange = (value) => {
    setValue(value);
    if (cellHandler) {
      cellHandler(id, value, email, index);
    }
  };

  let currentUserRoleId = useMemo(() => {
    let role = "unknown";
    if (schoolId && user && user.permission && user.permission.length) {
      const school = user.permission.find(
        (singleSchool) =>
          parseInt(singleSchool.schoolId, 10) === parseInt(schoolId, 10)
      );

      if (school && school.roleId) {
        role = school.roleId;
      }
    }

    return role;
  }, [user, schoolId]);

  const allClassesOpts = useMemo(() => {
    let res = [
      {
        value: null,
        label: "None",
      },
    ];

    if (allClasses && allClasses.length) {
      allClasses.forEach((singleClass) => {
        res.push({
          value: singleClass.id,
          label: singleClass.name,
        });
      });
    }

    return res;
  }, [allClasses]);

  const classOpts = useMemo(() => {
    let res = [
      {
        value: null,
        label: "None",
      },
    ];

    if (schoolId && allClasses && allClasses.length) {
      allClasses.forEach((singleClass) => {
        if (
          singleClass.schoolId &&
          parseInt(singleClass.schoolId, 10) === parseInt(schoolId, 10)
        ) {
          res.push({
            value: singleClass.id,
            label: singleClass.name,
          });
        }
      });
    }

    return res;
  }, [allClasses, schoolId]);

  const allRoleOpts = useMemo(() => {
    let res = [
      {
        value: null,
        label: "None",
      },
    ];

    if (allRoles && allRoles.length) {
      allRoles.forEach((singleRole) => {
        res.push({
          value: singleRole.roleId,
          label: singleRole.roleName,
        });
      });
    }

    return res;
  }, [allRoles]);

  const roleOpts = useMemo(() => {
    let res = [];

    if (allRoles && allRoles.length) {
      res = allRoles
        .filter((singleRole) => {
          return (
            singleRole.type === "school" &&
            singleRole.enabled &&
            (!singleRole.schoolId ||
              (schoolId &&
                parseInt(singleRole.schoolId, 10) ===
                  parseInt(schoolId, 10))) &&
            (isGA ||
              isDA ||
              addDisUsers ||
              parseInt(singleRole.roleId, 10) ===
                parseInt(currentUserRoleId, 10) ||
              !singleRole.assignable ||
              !singleRole.assignable.length ||
              singleRole.assignable.indexOf(currentUserRoleId) > -1)
          );
        })
        .map((singleRole) => ({
          value: singleRole.roleId,
          label: singleRole.roleName,
        }));
    }

    return res;
  }, [schoolId, allRoles, isGA, isDA, addDisUsers, currentUserRoleId]);

  const classRoleOpts = useMemo(() => {
    let res = [];

    if (allRoles && allRoles.length) {
      res = allRoles
        .filter((singleRole) => {
          return (
            singleRole.type === "class" &&
            singleRole.enabled &&
            (!singleRole.schoolId ||
              (schoolId &&
                parseInt(singleRole.schoolId, 10) ===
                  parseInt(schoolId, 10))) &&
            (isGA ||
              isDA ||
              addDisUsers ||
              parseInt(singleRole.roleId, 10) ===
                parseInt(currentUserRoleId, 10) ||
              !singleRole.assignable ||
              !singleRole.assignable.length ||
              singleRole.assignable.indexOf(currentUserRoleId) > -1)
          );
        })
        .map((singleRole) => ({
          value: singleRole.roleId,
          label: singleRole.roleName,
        }));
    }

    return res;
  }, [schoolId, allRoles, isGA, isDA, addDisUsers, currentUserRoleId]);

  const selectValue = useMemo(() => {
    let value = null;
    if (id === "schoolId" && schoolOpts && schoolOpts.length) {
      value = schoolOpts.find(({ value }) =>
        cmpVal === null
          ? cmpVal === value
          : parseInt(value, 10) === parseInt(cmpVal, 10)
      );
    }

    if (id === "classId" && allClassesOpts && allClassesOpts.length) {
      value = allClassesOpts.find(({ value }) =>
        cmpVal === null
          ? cmpVal === value
          : parseInt(value, 10) === parseInt(cmpVal, 10)
      );
    }

    if (
      (id === "roleId" || id === "classRole") &&
      allRoleOpts &&
      allRoleOpts.length
    ) {
      value = allRoleOpts.find(({ value }) =>
        cmpVal === null
          ? cmpVal === value
          : parseInt(value, 10) === parseInt(cmpVal, 10)
      );
    }

    return value;
  }, [id, cmpVal, schoolOpts, allRoleOpts, allClassesOpts]);

  if (
    id === "schoolId" ||
    id === "roleId" ||
    id === "classId" ||
    id === "classRole"
  ) {
    return (
      <Select
        id={`${id}-${index}`}
        inputId={`${id}-${index}-input`}
        value={selectValue}
        placeholder="Select.."
        onChange={({ value }) => onSelectChange(value)}
        options={
          id === "schoolId"
            ? schoolOpts
            : id === "roleId"
            ? roleOpts
            : id === "classId"
            ? classOpts
            : classRoleOpts
        }
        isPortal
        wrapperOptions={{
          $spacing: {
            mb: "0",
          },
          borderColor: rest[`${id}Error`] ? theme.colors.warning : undefined,
        }}
        isDisabled={
          (rest.error && (rest.error.user || rest.error.email)) ||
          (!schoolId &&
            (id === "roleId" || id === "classId" || id === "classRole")) ||
          (!classId && id === "classRole")
        }
      />
    );
  }

  return (
    <Input
      id={`${id}-${index}`}
      name={`${id}-${index}`}
      value={cmpVal}
      onChange={onChange}
      $spacing={{
        mb: "0",
      }}
      isDisabled={
        (rest.error && (rest.error.user || rest.error.email)) ||
        rest.inDistrict ||
        rest.inSchool
      }
      inputProps={{
        onBlur,
      }}
      borderColor={rest[`${id}Error`] ? theme.colors.warning : undefined}
    />
  );
};

export default CustomCell;
