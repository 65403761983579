import { FETCH_DISTRICT_INTEGRATIONS } from "../../../types";

const initialState = {
  platoVideo: null,
  whiteboardChat: null,
  clever: null,
  googleReportsApi: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DISTRICT_INTEGRATIONS: {
      let newIntegrations = {};

      if (payload.data && Object.keys(payload.data).length) {
        newIntegrations = {
          ...payload.data,
        };
      }

      return {
        ...initialState,
        ...newIntegrations,
        disId: payload.disId,
      };
    }
    default:
      return state;
  }
}
