import styled, { css } from "styled-components";
import { flex, textAlignment, spacing } from "../../Theme/styledOptions";
import { lighten } from "polished";

const headerIcoColor = "#89969f";

export const CustomRow = styled.tr`
  background-color: ${(props) => props.theme.colors.primaryLighter};
  font-weight: 600;

  .sticky-cell {
    background-color: ${(props) => props.theme.colors.primaryLighter};
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const TableHeaderItem = styled.div`
  margin-left: auto;
`;

export const TableHeaderItemDrop = styled.div`
  z-index: ${(props) => `${props.theme.zIndex.modal + 1}`};
`;

export const TableHeaderSort = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  line-height: 1;
  color: ${headerIcoColor};
  font-size: 9px;
`;

export const TableHeaderSortIco = styled.span`
  color: ${(props) =>
    props.$active ? props.theme.colors.primary : headerIcoColor};
`;

export const TableCellExpander = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 6px;
  width: 25px;
  height: 25px;
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primaryLightest};
  font-size: 12px;
  background-color: ${(props) => props.theme.colors.primaryLight};
  ${(props) =>
    props.$isExpanded
      ? css`
          border-color: ${(props) => props.theme.colors.primary};
        `
      : null}

  &:hover,
  &:active,
  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export const TablePaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TablePagination = styled.div`
  display: flex;
  align-items: center;

  .table-pagination-button {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primaryLight};
    border-radius: 12px;
    background-color: transparent;
    outline: none;
    padding: 0;
    margin: 0 2.5px;
    cursor: pointer;

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors.primaryLight};
      border-color: ${(props) => props.theme.colors.primary};
      outline: none;
    }

    &--side {
      background-color: ${(props) => props.theme.colors.primaryLight};
    }

    &--active {
      background-color: ${(props) => props.theme.colors.primaryLight};
      border-color: ${(props) => props.theme.colors.primary};

      &:disabled {
        opacity: 1;
        cursor: not-allowed;
      }
    }
  }
`;

export const TablePaginationMain = styled.div`
  margin: 0 6px;
  display: flex;
  align-items: center;
`;

export const TablePaginationSide = styled.div`
  margin: 0 6px;
`;

export const TablePaginationSizer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const StyledWrapper = styled.div`
  display: block;
  overflow-x: auto;
  border-radius: calc(${(props) => props.theme.borderRadius} / 2);
  box-shadow: ${(props) =>
    props.$topFlat
      ? `0 2px 4px ${props.theme.colors.boxShadow}`
      : props.theme.boxShadow};
  ${flex}
  ${textAlignment}
  ${spacing}

  ${(props) =>
    props.$topFlat
      ? css`
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        `
      : null}

      ${(props) =>
        props.$bottomFlat
          ? css`
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            `
          : null}

  .sticky-cell {
    position: sticky;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1px solid #e4e6ef;
    transition: background-color 0.2s ease;

    &:not(${CustomRow}) {
      td.sticky-cell {
        background-color: #fff;
      }
    }
  }

  th,
  td {
    text-align: left;

    &.text-center {
      text-align: center;
    }

    &.text-right {
      text-align: right;
    }
  }

  td {
    color: #000000;
    padding: 11px 17px;
    font-size: 12px;
  }

  th {
    color: #000000;
    text-transform: uppercase;
    padding: 11px 17px;
    white-space: nowrap;
    font-size: 10px;

    &.sticky-cell {
      background-color: ${(props) => props.theme.colors.primaryLightest};
    }

    .anticon {
      font-size: 16px;
      color: ${headerIcoColor};
      transition: color 0.2s ease;

      &:hover,
      &:active,
      &:focus {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }

  thead {
    tr {
      background-color: ${(props) => props.theme.colors.primaryLightest};
    }
  }

  tbody {
    tr {
      &:not(${CustomRow}) {
        &:hover {
          background-color: ${(props) =>
            lighten(0.01, props.theme.colors.primaryLightest)}};
        }
      }
    }
  }
`;

export default StyledWrapper;
