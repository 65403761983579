import React, { lazy, memo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import withSuspense from "./utils/withSuspense";

const Switcher = withSuspense(lazy(() => import("./views/Switcher")));
const Meet = withSuspense(lazy(() => import("./views/Meet")));
const Whiteboard = withSuspense(lazy(() => import("./views/Whiteboard")));
const Login = withSuspense(lazy(() => import("./views/Login")));
const Invite = withSuspense(lazy(() => import("./views/Invite")));
const JoinClass = withSuspense(lazy(() => import("./views/JoinClass")));
const Register = withSuspense(lazy(() => import("./views/Register")));
const GoogleReportsSync = withSuspense(
  lazy(() => import("./views/GoogleReportsSync"))
);
const GoogleMeetSync = withSuspense(
  lazy(() => import("./views/GoogleMeetSync"))
);
const GoogleDriveSync = withSuspense(
  lazy(() => import("./views/GoogleDriveSync"))
);
const ZoomSync = withSuspense(lazy(() => import("./views/ZoomSync")));
const TeamsSync = withSuspense(lazy(() => import("./views/TeamsSync")));
const GoogleAuth = withSuspense(lazy(() => import("./views/GoogleAuth")));
const CleverAuth = withSuspense(lazy(() => import("./views/CleverAuth")));
const ForgotPassword = withSuspense(
  lazy(() => import("./views/ForgotPassword"))
);
const ResetPassword = withSuspense(lazy(() => import("./views/ResetPassword")));

const AccountSettings = withSuspense(
  lazy(() => import("./views/AccountSettings"))
);
const SchoolsView = withSuspense(lazy(() => import("./views/Schools")));
const DistrictsView = withSuspense(lazy(() => import("./views/District")));

const Routes = () => {
  return (
    <Switch>
      <Route path="/google-meet-sync" exact component={GoogleMeetSync} />
      <Route path="/google-drive-sync" exact component={GoogleDriveSync} />
      <Route path="/google-reports-sync" exact component={GoogleReportsSync} />
      <Route path="/zoom-sync" exact component={ZoomSync} />
      <Route path="/teams-sync" exact component={TeamsSync} />
      <Route path="/google-auth" exact component={GoogleAuth} />
      <Route path="/clever-auth" exact component={CleverAuth} />
      <Route path="/invite" exact component={Invite} />
      <Route path="/join-class/:inviteCode" exact component={JoinClass} />
      <Route path="/meet/:id" exact component={Meet} />
      <Route path="/register/:token" exact component={Register} />
      <Route path={["/", "/login"]} exact component={Login} />
      <Route path="/reset-password/:token" exact component={ResetPassword} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <ProtectedRoute path="/switcher" component={Switcher} />
      <ProtectedRoute path="/dashboard" component={SchoolsView} />
      <ProtectedRoute path="/districts" component={DistrictsView} />
      <ProtectedRoute path="/account-settings" component={AccountSettings} />
      <ProtectedRoute path="/whiteboard" component={Whiteboard} />
      <Route path="/" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default memo(Routes);
