import PropTypes from "prop-types";

const RoleCheck = ({
  user,
  schoolId,
  disId,
  permissions,
  classPermissions,
  isAny,
  yes,
  globalYes,
  disAdminYes,
  no,
}) => {
  // globalYes === true, return yes
  if (globalYes && user.globalAdmin) {
    return yes();
  }

  if (disAdminYes && disId && user.districts && user.districts.length) {
    let disUser = user.districts.find(
      (singleDis) => parseInt(singleDis.districtId, 10) === parseInt(disId, 10)
    );
    if (disUser && disUser.isAdmin) {
      return yes();
    }
  }

  let userPermissions = {};
  if (classPermissions && Object.keys(classPermissions).length) {
    userPermissions = {
      ...userPermissions,
      ...classPermissions,
    };
  }

  if (schoolId && user.permission && user.permission.length) {
    let currentSchool = user.permission.find(
      (singleSchool) =>
        parseInt(singleSchool.schoolId, 10) === parseInt(schoolId, 10)
    );

    if (
      currentSchool &&
      currentSchool.list &&
      Object.keys(currentSchool.list).length
    ) {
      userPermissions = {
        ...userPermissions,
        ...currentSchool.list,
      };
    }
  }

  if (disId && user.districts && user.districts.length) {
    let currentDis = user.districts.find(
      (singleDis) => parseInt(singleDis.districtId, 10) === parseInt(disId, 10)
    );

    if (currentDis && currentDis.list && Object.keys(currentDis.list).length) {
      userPermissions = {
        ...userPermissions,
        ...currentDis.list,
      };
    }
  }

  if (!Object.keys(userPermissions).length) {
    return no();
  }

  if (typeof isAny !== "undefined" && isAny) {
    let hasAccess = false;
    if (permissions && permissions.length) {
      for (let permission of permissions) {
        const { key, level, isExactLevel, isBoolean, isComparison, a, b } =
          permission;
        if (isBoolean) {
          if (userPermissions.hasOwnProperty(key) && userPermissions[key]) {
            if (isComparison) {
              if (a === b) {
                hasAccess = true;
                break;
              }
            } else {
              hasAccess = true;
              break;
            }
          }
        } else {
          if (isExactLevel) {
            if (
              userPermissions.hasOwnProperty(key) &&
              parseInt(userPermissions[key], 10) === parseInt(level, 10)
            ) {
              if (isComparison) {
                if (a === b) {
                  hasAccess = true;
                  break;
                }
              } else {
                hasAccess = true;
                break;
              }
            }
          } else {
            if (
              userPermissions.hasOwnProperty(key) &&
              parseInt(userPermissions[key], 10) >= parseInt(level, 10)
            ) {
              if (isComparison) {
                if (a === b) {
                  hasAccess = true;
                  break;
                }
              } else {
                hasAccess = true;
                break;
              }
            }
          }
        }
      }
    } else {
      hasAccess = true;
    }
    return hasAccess ? yes() : no();
  } else {
    let hasAccess = true;
    if (permissions && permissions.length) {
      for (let permission of permissions) {
        const { key, level, isExactLevel, isBoolean, isComparison, a, b } =
          permission;
        if (isBoolean) {
          if (!userPermissions.hasOwnProperty(key) || !userPermissions[key]) {
            hasAccess = false;
            break;
          }

          if (isComparison) {
            if (a !== b) {
              hasAccess = false;
              break;
            }
          }
        } else {
          if (isExactLevel) {
            if (
              !userPermissions.hasOwnProperty(key) ||
              parseInt(userPermissions[key], 10) !== parseInt(level, 10)
            ) {
              hasAccess = false;
              break;
            }
          } else {
            if (
              !userPermissions.hasOwnProperty(key) ||
              parseInt(userPermissions[key], 10) < parseInt(level, 10)
            ) {
              hasAccess = false;
              break;
            }
          }

          if (isComparison) {
            if (a !== b) {
              hasAccess = false;
              break;
            }
          }
        }
      }
    }
    return hasAccess ? yes() : no();
  }
};

RoleCheck.propTypes = {
  user: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired,
  yes: PropTypes.func.isRequired,
  no: PropTypes.func.isRequired,
};

export default RoleCheck;
