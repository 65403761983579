import styled from "styled-components";
import { spacing } from "../../Theme/styledOptions";

export const ErrorField = styled.div`
  color: ${(props) => props.theme.colors.warning};
  font-size: 12px;
  ${spacing}
`;

const StyledWrapper = styled.div`
  display: block;
  width: ${(props) => (props.$width ? props.$width : "100%")};
  ${spacing}

  input {
    height: ${(props) =>
      props.$size
        ? props.theme.heights[props.$size]
          ? props.theme.heights[props.$size]
          : props.$size
        : props.theme.heights.middle};
    width: 100%;
    padding: 7px;
    background-color: ${(props) =>
      props.$isDisabled
        ? props.theme.colors.disabled
        : props.bgColor
        ? props.bgColor
        : props.theme.colors.primaryLightest};
    border-color: ${(props) =>
      props.$isDisabled
        ? props.theme.colors.disabled
        : props.borderColor
        ? props.borderColor
        : props.theme.colors.primaryLightest};
    border-radius: ${(props) => props.theme.borderRadius};
    border-width: 1px;
    border-style: solid;
    font-size: 14px;
    color: ${(props) => (props.$color ? props.$color : "")};
    appearance: none;
    outline: none;
    line-height: ${(props) =>
      props.$size
        ? props.theme.heights[props.$size]
          ? props.theme.heights[props.$size]
          : props.$size
        : props.theme.heights.middle};
    cursor: ${(props) =>
      props.$customCursor
        ? props.$customCursor
        : props.$isDisabled
        ? "not-allowed"
        : "default"};
    text-align: ${(props) => (props.$textAlign ? props.$textAlign : "left")};

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) =>
        props.$isDisabled
          ? props.theme.colors.disabled
          : props.hoverBgColor
          ? props.hoverBgColor
          : props.bgColor
          ? props.bgColor
          : props.theme.colors.primaryLightest};
      border-color: ${(props) =>
        props.$isDisabled
          ? props.theme.colors.disabled
          : props.hoverBorderColor
          ? props.hoverBorderColor
          : props.borderColor
          ? props.borderColor
          : props.theme.colors.primary};
    }
  }
`;

export default StyledWrapper;
