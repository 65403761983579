import { FETCH_DISTRICT_INFO, UPDATE_DISTRICT_SETTINGS } from "../../../types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DISTRICT_INFO: {
      return {
        ...payload,
      };
    }
    case UPDATE_DISTRICT_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
}
