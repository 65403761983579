import styled from "styled-components";
import { flex, textAlignment, spacing } from "../../Theme/styledOptions";

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: "0 7px",
    boxShadow: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
};

export const ErrorField = styled.div`
  color: ${(props) => props.theme.colors.warning};
  font-size: 12px;
`;

const StyledWrapper = styled.div`
  ${flex}
  ${textAlignment}
  ${spacing}

  .custom-select-element {
    &__control {
      min-height: ${(props) =>
        props.$size && props.theme.heights[props.$size]
          ? props.theme.heights[props.$size]
          : props.theme.heights.middle};
      width: ${(props) => (props.$width ? props.$width : "100%")};
      min-width: auto;
      background-color: ${(props) =>
        props.bgColor ? props.bgColor : props.theme.colors.primaryLightest};
      border-color: ${(props) =>
        props.borderColor
          ? props.borderColor
          : props.theme.colors.primaryLightest};
      border-radius: ${(props) => props.theme.borderRadius};
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: ${(props) =>
          props.hoverBgColor
            ? props.hoverBgColor
            : props.bgColor
            ? props.bgColor
            : props.theme.colors.primaryLightest};
        border-color: ${(props) =>
          props.hoverBorderColor
            ? props.hoverBorderColor
            : props.borderColor
            ? props.borderColor
            : props.theme.colors.primary};
      }

      &--is-disabled {
        background-color: ${(props) => props.theme.colors.disabled};
        cursor: not-allowed;

        .custom-select-element__indicator {
          opacity: 0.5;
        }
      }

      &--is-focused {
        border-color: ${(props) =>
          props.hoverBorderColor
            ? props.hoverBorderColor
            : props.borderColor
            ? props.borderColor
            : props.theme.colors.primary};
      }
    }

    &__value-container {
      line-height: 1;
      padding: 1px 8px 1px 0;
    }

    &__indicator {
      padding: 0 7px;
    }

    &__clear-indicator {
      color: ${(props) => props.theme.colors.warning};
      opacity: 0.6;
      transition: opacity 0.2s ease;

      &:hover {
        color: ${(props) => props.theme.colors.warning};
        opacity: 1;
      }
    }

    &__dropdown-indicator {
      color: ${(props) => props.theme.colors.primary};

      &:hover {
        color: ${(props) => props.theme.colors.primary};
      }
    }

    &__multi-value {
      border-radius: ${(props) => props.theme.borderRadius};
      background-color: ${(props) => props.theme.colors.primaryLight};

      &__remove {
        color: ${(props) => props.theme.colors.primary};
        border-top-right-radius: ${(props) => props.theme.borderRadius};
        border-top-left-radius: 0;
        border-bottom-right-radius: ${(props) => props.theme.borderRadius};
        border-bottom-left-radius: 0;
      }

      &__label {
        color: ${(props) => props.theme.colors.primary};
        border-radius: ${(props) => props.theme.borderRadius};
        font-weight: 500;
        padding: 2px 3px;
      }
    }

    &__single-value {
      color: ${(props) => (props.valueColor ? props.valueColor : "")};
    }

    &__placeholder {
      color: ${(props) =>
        props.placeholderColor ? props.placeholderColor : ""};
    }
  }
`;

export default StyledWrapper;
