import {
  FETCH_USER_PROFILE,
  FETCH_USER_ANECDOTALS,
  UPDATE_USER_ANECDOTAL,
  DELETE_USER_ANECDOTAL,
  FETCH_USER_IEPS,
  UPDATE_USER_IEP,
  DELETE_USER_IEP,
  FETCH_USER_TAGS,
  DELETE_USER_TAG,
  FETCH_USER_CLASSES,
  FETCH_USER_GRADES,
  FETCH_USER_INTEGRATIONS,
} from "../../types";

const initialState = {
  profile: {},
  anecdotals: {
    userId: null,
    params: null,
    list: [],
  },
  ieps: {
    userId: null,
    params: null,
    list: [],
  },
  tags: {
    params: null,
    list: [],
  },
  classes: {
    userId: null,
    params: null,
    list: [],
  },
  grades: {
    userId: null,
    params: null,
    periodsList: [],
    classesList: [],
  },
  integrations: {
    userId: null,
    zoomApi: null,
    googleMeetApi: null,
    teamsApi: null,
    googleDriveApi: null,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER_PROFILE: {
      return {
        ...state,
        profile:
          payload && payload.id
            ? {
                ...payload,
                id: parseInt(payload.id, 10),
              }
            : {},
      };
    }
    case FETCH_USER_ANECDOTALS: {
      return {
        ...state,
        anecdotals: {
          userId: payload.userId,
          params: payload.params
            ? {
                ...payload.params,
              }
            : null,
          list: payload.list && payload.list.length ? [...payload.list] : [],
        },
      };
    }
    case UPDATE_USER_ANECDOTAL: {
      let newList = [...state.anecdotals.list],
        idx = newList.findIndex((singleEl) => singleEl.id === payload.id);

      if (idx > -1) {
        newList.splice(idx, 1, {
          ...newList[idx],
          ...payload.data,
        });
      }
      return {
        ...state,
        anecdotals: {
          ...state.anecdotals,
          list: newList,
        },
      };
    }
    case DELETE_USER_ANECDOTAL: {
      let newList = [...state.anecdotals.list],
        idx = newList.findIndex((singleEl) => singleEl.id === payload);

      if (idx > -1) {
        newList.splice(idx, 1);
      }
      return {
        ...state,
        anecdotals: {
          ...state.anecdotals,
          list: newList,
        },
      };
    }

    case FETCH_USER_IEPS: {
      return {
        ...state,
        ieps: {
          userId: payload.userId,
          params: payload.params
            ? {
                ...payload.params,
              }
            : null,
          list: payload.list && payload.list.length ? [...payload.list] : [],
        },
      };
    }
    case UPDATE_USER_IEP: {
      let newList = [...state.ieps.list],
        idx = newList.findIndex((singleEl) => singleEl.id === payload.id);

      if (idx > -1) {
        newList.splice(idx, 1, {
          ...newList[idx],
          ...payload.data,
        });
      }
      return {
        ...state,
        ieps: {
          ...state.ieps,
          list: newList,
        },
      };
    }
    case DELETE_USER_IEP: {
      let newList = [...state.ieps.list],
        idx = newList.findIndex((singleEl) => singleEl.id === payload);

      if (idx > -1) {
        newList.splice(idx, 1);
      }
      return {
        ...state,
        ieps: {
          ...state.ieps,
          list: newList,
        },
      };
    }

    case FETCH_USER_TAGS: {
      return {
        ...state,
        tags: {
          params: payload.params
            ? {
                ...payload.params,
              }
            : null,
          list: payload.list && payload.list.length ? [...payload.list] : [],
        },
      };
    }
    case DELETE_USER_TAG: {
      let newList = [...state.tags.list],
        idx = newList.findIndex((singleEl) => singleEl.relationId === payload);

      if (idx > -1) {
        newList.splice(idx, 1);
      }
      return {
        ...state,
        tags: {
          ...state.tags,
          list: newList,
        },
      };
    }

    case FETCH_USER_CLASSES: {
      return {
        ...state,
        classes: {
          userId: payload.userId,
          params: payload.params
            ? {
                ...payload.params,
              }
            : null,
          list: payload.list && payload.list.length ? [...payload.list] : [],
        },
      };
    }

    case FETCH_USER_GRADES: {
      return {
        ...state,
        grades: {
          userId: payload.userId,
          params: payload.params
            ? {
                ...payload.params,
              }
            : null,
          classesList: payload.classesList,
          periodsList: payload.periodsList,
        },
      };
    }

    case FETCH_USER_INTEGRATIONS: {
      let newIntegrations = {};

      if (payload.data && Object.keys(payload.data).length) {
        newIntegrations = {
          ...payload.data,
        };
      }

      return {
        ...state,
        integrations: {
          ...initialState.integrations,
          ...newIntegrations,
          userId: payload.userId,
        },
      };
    }

    default:
      return state;
  }
}
