import React from "react";
import StyledWrapper from "./StyledWrapper";

const Label = ({ text, isRequired, ...props }) => {
  return (
    <StyledWrapper {...props}>
      {text}
      {isRequired ? <span>*</span> : null}
    </StyledWrapper>
  );
};

export default Label;
