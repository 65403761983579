import { OPEN_MESSAGING, CLOSE_MESSAGING } from "../../types";

const initialState = {
  visible: false,
  data: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_MESSAGING: {
      let data = null;
      if (payload && Object.keys(payload).length) {
        data = {
          ...payload,
        };
      }
      return {
        ...state,
        visible: true,
        data,
      };
    }
    case CLOSE_MESSAGING: {
      return {
        ...state,
        visible: false,
        data: null,
      };
    }
    default:
      return state;
  }
}
