const encodeURL = (data) => {
  if (data && Object.keys(data).length) {
    let res = [];
    for (let key in data) {
      if (data[key]) {
        res.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
      }
    }

    return res.join("&");
  } else {
    return "";
  }
};

export default encodeURL;
