import styled from "styled-components";
import { spacing } from "../../Theme/styledOptions";

const StyledWrapper = styled.div`
  margin: 30px 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${spacing}
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 32px;
  height: 4px;
  border-radius: 20px;
  position: relative;
  background-color: ${(props) => props.theme.colors.primaryLightest};
  width: ${(props) => (props.$width ? props.$width : "100%")};
  ${spacing};
`;

export const StyledListItem = styled.li`
  position: absolute;
  top: 0;
  cursor: ${(props) => (props.$clickable ? "pointer" : "default")};
`;

export const StyledDot = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  position: absolute;
  top: 2px;
  transform: translateY(-50%);
`;

export const StyledDotBg = styled.span`
  position: absolute;
  background-color: ${(props) => props.theme.colors.primary};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 2px;
  left: 3px;
  transform: translate(-50%, -50%);
  opacity: ${(props) => (props.$active ? "0.5" : "0")};
`;

export const StyledText = styled.div`
  position: absolute;
  top: 14px;
  left: 3px;
  transform: translateX(-50%);
  width: 65px;
  text-align: center;
  font-size: 8px;
  font-weight: 500;
  text-transform: uppercase;
`;

export default StyledWrapper;
