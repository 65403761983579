import styled, { css } from "styled-components";
import { spacing } from "../../Theme/styledOptions";
import { darken } from "polished";

import Dropdown from "../Dropdown/StyledWrapper";

export const FileListDeleter = styled.span`
  color: ${(props) => props.theme.colors.warning};
  font-size: 12px;
  margin-left: 7px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
`;

export const FileList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  ${spacing}

  li {
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    + li {
      margin-top: 5px;
    }

    &:hover,
    &:active,
    &:focus {
      ${FileListDeleter} {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

export const ErrorField = styled.div`
  color: ${(props) => props.theme.colors.warning};
  font-size: 12px;
  ${spacing}
`;

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  color: ${(props) => darken(0.1, props.theme.colors.primaryLight)};
  ${spacing};

  > .anticon {
    font-size: 24px;
  }

  > p {
    margin: 3px 0 0;
  }
`;

export const UploaderIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;

  &:hover {
    background-color: ${(props) => darken(0.02, props.theme.colors.primary)};
  }
`;

export const UploaderWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => (!props.$isRounded ? "-7px" : "0")};
  right: ${(props) => (!props.$isRounded ? "calc(50% - 12px)" : "0")};

  ${Dropdown} {
    transform: translateX(-50%);
  }
`;

export const DeleterWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => (!props.$isRounded ? "-7px" : "0")};
  right: ${(props) => (!props.$isRounded ? "calc(50% - 12px)" : "0")};
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.warning};
  color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  ${spacing}

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.warning)};
  }
`;

export const DropzoneContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledWrapper = styled.div`
  height: ${(props) => (props.$height ? props.$height : "90px")};
  width: ${(props) => (props.$width ? props.$width : "100%")};
  background-color: ${(props) =>
    props.$disabled
      ? props.theme.colors.disabled
      : props.bgColor
      ? props.bgColor
      : props.theme.colors.primaryLightest};
  border-color: ${(props) =>
    props.$disabled
      ? props.theme.colors.disabled
      : props.borderColor
      ? props.borderColor
      : props.theme.colors.primaryLight};
  border-radius: ${(props) =>
    props.$isRounded ? "50%" : props.theme.borderRadius};
  border-width: 1px;
  border-style: dashed;
  font-size: 14px;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  display: inline-block;
  position: relative;
  ${spacing};

  ${(props) =>
    props.$bgImg
      ? css`
          background-image: url(${props.$bgImg});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        `
      : null}

  &:hover {
    background-color: ${(props) =>
      props.$disabled
        ? props.theme.colors.disabled
        : props.hoverBgColor
        ? props.hoverBgColor
        : props.bgColor
        ? props.bgColor
        : darken(0.01, props.theme.colors.primaryLightest)};
    border-color: ${(props) =>
      props.$disabled
        ? props.theme.colors.disabled
        : props.hoverBorderColor
        ? props.hoverBorderColor
        : props.borderColor
        ? props.borderColor
        : props.theme.colors.primaryLight};
  }
`;

export default StyledWrapper;
