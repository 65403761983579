import {
  FETCH_ALL_USERS,
  DELETE_SINGLE_USER,
  SET_USERS_FILTERS,
  UPDATE_USER_SWITCHER,
  RESET_DISTRICT_DATA,
  UPDATE_LOGGED_USER,
  FETCH_USER_PROFILE,
} from "../../types";

import axios from "axios";
import encodeURL from "../../../utils/encodeURL";
import { authToken } from "../auth";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const setUserFiltersAction = (data, shouldMerge) => ({
  type: SET_USERS_FILTERS,
  payload: {
    data,
    shouldMerge: !!shouldMerge,
  },
});

export const dispatchLoggedUserUpdateAction = (data) => ({
  type: UPDATE_LOGGED_USER,
  payload: {
    ...data,
  },
});

export const dispatchUserProfileUpdateAction = (data) => ({
  type: FETCH_USER_PROFILE,
  payload: {
    ...data,
  },
});

export const fetchUsersAction = (params) => async (dispatch) => {
  let urlParams = encodeURL(params);
  try {
    const res = await axios.get(
      `/users${urlParams ? `?${urlParams}` : ""}`,
      config
    );

    dispatch({
      type: FETCH_ALL_USERS,
      payload: {
        params,
        data: res.data,
      },
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// NOT USED CURRENTLY
export const fetchUserEmailAction = async (email) => {
  try {
    const res = await axios.get(`/users/email/${email}`, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error ocurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const fetchSingleUserAction = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/users/${id}`, config);

    dispatch({
      type: FETCH_USER_PROFILE,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error ocurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const createUserAction = (data) => async (dispatch, getState) => {
  const body = JSON.stringify(data),
    currentState = getState();

  try {
    const res = await axios.post(`/users`, body, config);

    if (res && res.data && res.data.length) {
      res.data.forEach((el) => {
        if (
          currentState.auth.user.id &&
          parseInt(currentState.auth.user.id, 10) === parseInt(el.id, 10)
        ) {
          dispatch(dispatchLoggedUserUpdateAction(el));
        }

        if (
          currentState.users.single.profile.id &&
          parseInt(currentState.users.single.profile.id, 10) ===
            parseInt(el.id, 10)
        ) {
          dispatch(dispatchUserProfileUpdateAction(el));
        }
      });
    }

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        throw new Error(
          err.response.data && err.response.data.errorMessage
            ? err.response.data.errorMessage
            : "Email is already in use."
        );
      } else if (err.response.status === 422) {
        throw new Error(
          "Some of the fields are incorrect. Please check again.."
        );
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateUserAction = (data) => async (dispatch, getState) => {
  const body = JSON.stringify({ ...data }),
    currentState = getState();

  try {
    const res = await axios.put(`/users/${data.id}`, body, config);

    if (res.data && res.data.id) {
      if (
        currentState.auth.user.id &&
        parseInt(currentState.auth.user.id, 10) === parseInt(res.data.id, 10)
      ) {
        dispatch(dispatchLoggedUserUpdateAction(res.data));
      }

      if (
        currentState.users.single.profile.id &&
        parseInt(currentState.users.single.profile.id, 10) ===
          parseInt(res.data.id, 10)
      ) {
        dispatch(dispatchUserProfileUpdateAction(res.data));
      }
    }

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        throw new Error(
          err.response.data && err.response.data.errorMessage
            ? err.response.data.errorMessage
            : "Email is already in use."
        );
      } else if (err.response.status === 422) {
        throw new Error(
          "Some of the fields are incorrect. Please check again.."
        );
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateUserPermissionsAction =
  (data) => async (dispatch, getState) => {
    const body = JSON.stringify({ ...data }),
      currentState = getState();

    try {
      const res = await axios.put(
        `/users/${data.id}/permissions`,
        body,
        config
      );

      if (res.data && res.data.id) {
        if (
          currentState.auth.user.id &&
          parseInt(currentState.auth.user.id, 10) === parseInt(res.data.id, 10)
        ) {
          dispatch(dispatchLoggedUserUpdateAction(res.data));
        }

        if (
          currentState.users.single.profile.id &&
          parseInt(currentState.users.single.profile.id, 10) ===
            parseInt(res.data.id, 10)
        ) {
          dispatch(dispatchUserProfileUpdateAction(res.data));
        }
      }

      return res.data;
    } catch (err) {
      if (err.response) {
        if (err.response.status === 409) {
          throw new Error(
            err.response.data && err.response.data.errorMessage
              ? err.response.data.errorMessage
              : "Email is already in use."
          );
        } else if (err.response.status === 422) {
          throw new Error(
            "Some of the fields are incorrect. Please check again.."
          );
        } else {
          throw new Error("An error occurred. Please try again later..");
        }
      } else {
        throw new Error(
          "Network error - Please check your network connection or firewall settings and try again."
        );
      }
    }
  };

export const updateUserPasswordAction = (userId, password) => {
  const body = JSON.stringify({ password });

  return axios.put(`/users/${userId}/password`, body, config);
};

/**
 * Validate user emails
 * schoolId
 * districtId
 * emails
 */
export const validateUsersAction = async (data) => {
  const body = JSON.stringify({ ...data });

  try {
    const res = await axios.post("/users/validate", body, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error occurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

/**
 * Verify current user data like phone or email
 * type
 * code
 */
export const verifyUserAction = async (data) => {
  const body = JSON.stringify({ ...data });

  try {
    const res = await axios.post("/users/verify", body, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        throw new Error(
          err.response.data && err.response.data.errorMessage
            ? err.response.data.errorMessage
            : "Code is not valid anymore."
        );
      } else if (err.response.status === 400) {
        throw new Error("Code is not valid. Please check again..");
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const uploadUsersCSVAction = async (data) => {
  const body = JSON.stringify({ ...data });

  try {
    const res = await axios.post("/users/csv", body, config);
    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.message);
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const deleteUserAction = (id, params) => async (dispatch, getState) => {
  let urlParams = encodeURL(params),
    currentState = getState();
  try {
    await axios.delete(
      `/users/${id}/${urlParams ? `?${urlParams}` : ""}`,
      config
    );

    dispatch({
      type: DELETE_SINGLE_USER,
      payload: id,
    });

    if (
      currentState.auth &&
      currentState.auth.user &&
      parseInt(currentState.auth.user.id, 10) === parseInt(id, 10)
    ) {
      await dispatch(authToken(window.localStorage.practiceToken));
    }
  } catch (err) {
    if (err.response) {
      throw new Error("Could not delete the user. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const mergeUsersAction = async (data) => {
  const body = JSON.stringify(data);
  try {
    await axios.post("/users/merge", body, config);
  } catch (err) {
    if (err.response) {
      throw new Error("Could not merge users. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateUserSwitcher = (data, resetData) => async (dispatch) => {
  const body = JSON.stringify(data);

  try {
    await axios.post(`/users/switcher`, body, config);

    dispatch({
      type: UPDATE_USER_SWITCHER,
      payload: data,
    });

    if (resetData) {
      dispatch({
        type: RESET_DISTRICT_DATA,
      });
    }
  } catch (err) {
    if (err.response) {
      throw new Error("Could not update the user. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// User Terms
export const fetchUserTermsAction = async (userId) => {
  try {
    const res = await axios.get(`/users/${userId}/terms`, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error ocurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Join Class by Code

export const joinClassCodeAction = async (userId, inviteCode) => {
  const body = JSON.stringify({ inviteCode });

  try {
    const res = await axios.put(`/users/${userId}/join-class`, body, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        throw new Error(
          "You are already part of this class or code is invalid."
        );
      } else {
        throw new Error("An error ocurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};
