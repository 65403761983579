import React, { useState, useEffect } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Select from "../Select";

import {
  TablePagination,
  TablePaginationWrapper,
  TablePaginationMain,
  TablePaginationSide,
  TablePaginationSizer,
} from "./StyledWrapper";

const paginationOptions = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
];

const Button = (props) => <button {...props}>{props.children}</button>;

const Pagination = (props) => {
  const [visiblePages, editPages] = useState([]);

  useEffect(() => {
    let visiblePages = getVisiblePages(props.pageIndex + 1, props.pageCount);
    editPages(visiblePages);

    // eslint-disable-next-line
  }, [props]);

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  return (
    <TablePaginationWrapper>
      <TablePagination>
        {props.canPreviousPage ? (
          <TablePaginationSide>
            <Button
              className="table-pagination-button table-pagination-button--side"
              onClick={() => {
                window.scrollTo(0, 0);
                props.previousPage();
              }}
            >
              <LeftOutlined />
            </Button>
          </TablePaginationSide>
        ) : null}
        <TablePaginationMain>
          {visiblePages.map((page, index, array) => {
            return (
              <Button
                key={page}
                className={`table-pagination-button${
                  props.pageIndex + 1 === page
                    ? " table-pagination-button--active"
                    : ""
                }`}
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.gotoPage(page - 1);
                }}
                disabled={props.pageIndex + 1 === page}
              >
                {array[index - 1] + 2 < page ? `...${page}` : page}
              </Button>
            );
          })}
        </TablePaginationMain>
        {props.canNextPage ? (
          <TablePaginationSide>
            <Button
              className="table-pagination-button table-pagination-button--side"
              onClick={() => {
                window.scrollTo(0, 0);
                props.nextPage();
              }}
            >
              <RightOutlined />
            </Button>
          </TablePaginationSide>
        ) : null}
      </TablePagination>

      <TablePaginationSizer>
        Show
        <Select
          className="table-pagesize"
          value={
            props.pageSize
              ? paginationOptions.find(
                  ({ value }) =>
                    parseInt(props.pageSize, 10) === parseInt(value, 10)
                )
              : undefined
          }
          placeholder="Size"
          onChange={({ value }) => props.setPageSize(value)}
          options={paginationOptions}
          isPortal
          wrapperOptions={{
            $width: "80px",
            $spacing: {
              m: "0 10px",
            },
          }}
        />
        results of total {props.dataSize}
      </TablePaginationSizer>
    </TablePaginationWrapper>
  );
};

export default Pagination;
