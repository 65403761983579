import {
  FETCH_ALL_USERS,
  DELETE_SINGLE_USER,
  SET_USERS_FILTERS,
} from "../../types";

const initialState = {
  params: null,
  data: [],
  filters: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ALL_USERS: {
      return {
        ...state,
        params: payload.params
          ? {
              ...payload.params,
            }
          : null,
        data: payload.data && payload.data.length ? [...payload.data] : [],
      };
    }
    case DELETE_SINGLE_USER: {
      let newData = [...state.data],
        idx = newData.findIndex((singleEl) => singleEl.id === payload);

      if (idx > -1) {
        newData.splice(idx, 1);
      }
      return {
        ...state,
        data: newData,
      };
    }

    case SET_USERS_FILTERS: {
      let newFilters = {};

      if (payload && payload.data && Object.keys(payload.data).length) {
        if (payload.shouldMerge) {
          newFilters = {
            ...state.filters,
            ...payload.data,
          };
        } else {
          newFilters = {
            ...payload.data,
          };
        }
      }

      return {
        ...state,
        filters: newFilters,
      };
    }
    default:
      return state;
  }
}
