import React, { Suspense } from "react";
import { Spin } from "antd";

const withSuspense = (WrapperComponent) => (props) => {
  return (
    <Suspense
      fallback={
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Spin />
        </div>
      }
    >
      <WrapperComponent {...props} />
    </Suspense>
  );
};

export default withSuspense;
