import {
  FETCH_ALL_SCHOOLS,
  SET_SINGLE_SCHOOL,
  UPDATE_SINGLE_SCHOOL,
  DELETE_SINGLE_SCHOOL,
} from "../../types";

import axios from "axios";
import encodeURL from "../../../utils/encodeURL";
import { authToken } from "../auth";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const fetchLoginSchoolsAction = async () => {
  try {
    const res = await axios.get("/loginSchools", config);

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error occurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const fetchSchoolsAction = (params) => async (dispatch) => {
  let urlParams = encodeURL(params);
  try {
    const res = await axios.get(
      `/schools${urlParams ? `?${urlParams}` : ""}`,
      config
    );

    dispatch({
      type: FETCH_ALL_SCHOOLS,
      payload: {
        params,
        data: res.data,
      },
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const fetchSingleSchoolAction = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/schools/${id}`, config);

    dispatch({
      type: SET_SINGLE_SCHOOL,
      payload: {
        ...res.data,
      },
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const createSchoolAction = (data) => async (dispatch) => {
  const body = JSON.stringify({ ...data });

  try {
    await axios.post("/schools", body, config);

    await dispatch(authToken(window.localStorage.practiceToken));
  } catch (err) {
    if (err.response) {
      throw new Error("Could not create school. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateSchoolAction = (data) => async (dispatch, getState) => {
  const body = JSON.stringify(data),
    currentState = getState();

  try {
    await axios.put(`/schools/${data.id}`, body, config);

    dispatch({
      type: UPDATE_SINGLE_SCHOOL,
      payload: data,
    });

    let isInSchool = null;
    if (
      data.id &&
      currentState.auth &&
      currentState.auth.user &&
      currentState.auth.user.permission &&
      currentState.auth.user.permission.length
    ) {
      isInSchool = currentState.auth.user.permission.find(
        (singleSchool) =>
          parseInt(singleSchool.schoolId, 10) === parseInt(data.id, 10)
      );
    }

    if (isInSchool) {
      try {
        await dispatch(authToken(window.localStorage.practiceToken));
      } catch (err) {
        console.log(err.message);
      }
    }
  } catch (err) {
    if (err.response) {
      throw new Error("Could not update the school. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const deleteSchoolAction = (id) => async (dispatch, getState) => {
  const currentState = getState();
  try {
    await axios.delete(`schools/${id}`, config);

    dispatch({
      type: DELETE_SINGLE_SCHOOL,
      payload: id,
    });

    let isInSchool = null;
    if (
      currentState.auth &&
      currentState.auth.user &&
      currentState.auth.user.permission &&
      currentState.auth.user.permission.length
    ) {
      isInSchool = currentState.auth.user.permission.find(
        (singleSchool) =>
          parseInt(singleSchool.schoolId, 10) === parseInt(id, 10)
      );
    }

    if (isInSchool) {
      try {
        await dispatch(authToken(window.localStorage.practiceToken));
      } catch (err) {
        console.log(err.message);
      }
    }
  } catch (err) {
    if (err.response) {
      throw new Error("Could not delete the school. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};
