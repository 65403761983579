import {
  FETCH_DISTRICT_GRADEBOOKS,
  UPDATE_DISTRICT_GRADEBOOK,
  DELETE_DISTRICT_GRADEBOOK,
  RESET_DISTRICT_DATA,
} from "../../../types";

const initialState = {
  list: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DISTRICT_GRADEBOOKS: {
      return {
        ...state,
        list: [...payload],
      };
    }
    case UPDATE_DISTRICT_GRADEBOOK: {
      let newList = [...state.list],
        idx = newList.findIndex((singleEl) => singleEl.id === payload.id);

      if (idx > -1) {
        newList.splice(idx, 1, {
          ...payload,
        });
      }
      return {
        ...state,
        list: newList,
      };
    }
    case DELETE_DISTRICT_GRADEBOOK: {
      let newList = [...state.list],
        idx = newList.findIndex((singleEl) => singleEl.id === payload);

      if (idx > -1) {
        newList.splice(idx, 1);
      }
      return {
        ...state,
        list: newList,
      };
    }
    case RESET_DISTRICT_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
