import {
  FETCH_ALL_ROLES,
  UPDATE_SINGLE_ROLE,
  DELETE_SINGLE_ROLE,
} from "../../types";
import { authToken } from "../auth";

import axios from "axios";
import encodeURL from "../../../utils/encodeURL";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const fetchRolesAction = (params) => async (dispatch) => {
  let urlParams = encodeURL(params);
  try {
    const res = await axios.get(
      `/roles${urlParams ? `?${urlParams}` : ""}`,
      config
    );

    dispatch({
      type: FETCH_ALL_ROLES,
      payload: {
        params,
        data: res.data,
      },
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const fetchSingleRoleAction = async (id) => {
  try {
    const res = await axios.get(`/roles/${id}`, config);
    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const createRoleAction = async (data) => {
  const body = JSON.stringify({ ...data });

  try {
    await axios.post("/roles", body, config);
  } catch (err) {
    if (err.response) {
      throw new Error("Could not create role. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateRoleAction = (data) => async (dispatch, getState) => {
  const body = JSON.stringify({ ...data }),
    currentState = getState();

  try {
    await axios.put(`/roles/${data.roleId}`, body, config);

    dispatch({
      type: UPDATE_SINGLE_ROLE,
      payload: data,
    });

    if (data.type === "district") {
      let isInDis = null;
      if (
        data.districtId &&
        currentState.auth &&
        currentState.auth.user &&
        currentState.auth.user.districts &&
        currentState.auth.user.districts.length
      ) {
        isInDis = currentState.auth.user.districts.find(
          (singleDis) =>
            parseInt(singleDis.districtId, 10) === parseInt(data.districtId, 10)
        );
      }

      if (isInDis) {
        try {
          await dispatch(authToken(window.localStorage.practiceToken));
        } catch (err) {
          console.log(err.message);
        }
      }
    } else {
      let isInSchool = null;
      if (
        data.schoolId &&
        currentState.auth &&
        currentState.auth.user &&
        currentState.auth.user.permission &&
        currentState.auth.user.permission.length
      ) {
        isInSchool = currentState.auth.user.permission.find(
          (singleSchool) =>
            parseInt(singleSchool.schoolId, 10) === parseInt(data.schoolId, 10)
        );
      }

      if (!data.schoolId || isInSchool) {
        try {
          await dispatch(authToken(window.localStorage.practiceToken));
        } catch (err) {
          console.log(err.message);
        }
      }
    }
  } catch (err) {
    if (err.response) {
      throw new Error("Could not update the role. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const deleteRoleAction = (id) => async (dispatch) => {
  try {
    await axios.delete(`/roles/${id}`, config);

    dispatch({
      type: DELETE_SINGLE_ROLE,
      payload: id,
    });
  } catch (err) {
    if (err.response) {
      throw new Error("Could not delete the role. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};
