import React, {
  Fragment,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { connect } from "react-redux";
import { fetchUserIntegrationsAction } from "../../../redux/actions/Users/integrations";

import { useDropzone } from "react-dropzone";
import initGoogleDrive from "../../../utils/googleDrive";
import useOutside from "../../useOutside";

import {
  DeleteOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FileFilled,
  PlusOutlined,
} from "@ant-design/icons";

import Label from "../Label";
import Dropdown from "../Dropdown";
import StyledWrapper, {
  DropzoneContainer,
  Placeholder,
  UploaderWrapper,
  UploaderIcon,
  DeleterWrapper,
  ErrorField,
  FileList,
  FileListDeleter,
} from "./StyledWrapper";

import theme from "../../Theme";
import { message } from "antd";

/**
 * @param {*} acceptGDrive should be mime type and have no spaces after comma.
 */

const Uploader = ({
  currentUser,
  fetchUserIntegrationsAction,
  userIntegrations,
  multiple,
  onChange,
  customOnChange,
  onError,
  onDelete,
  label,
  id,
  value,
  customPlaceholder,
  placeholderIcon,
  hidePlaceholderIcon,
  hidePlaceholderText,
  placeholderText,
  placeholderOpts,
  placeholderWrappperOpts,
  wrapperOpts,
  isRequired,
  isError,
  errorMessage,
  errorOptions,
  showList,
  listOptions,
  disableGDrive,
  includeFile,
  accept = "image/*, .mp4, application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  acceptGDrive = "image/png,image/jpeg,image/jpg,video/mp4,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ...props
}) => {
  const uploaderRef = useRef(null);
  const [optionsVisible, setOptionsVisibility] = useState(false);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          if (
            !userIntegrations ||
            !userIntegrations.userId ||
            parseInt(userIntegrations.userId, 10) !==
              parseInt(currentUser.id, 10)
          ) {
            await fetchUserIntegrationsAction(currentUser.id);
          }
        } catch (err) {
          console.log(err);
        }
      };

      fetchData();
    },
    // eslint-disable-next-line
    [currentUser, fetchUserIntegrationsAction]
  );

  const getBase64 = useCallback(
    (file) =>
      new Promise((res) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          res(reader.result);
        });
        reader.readAsDataURL(file);
      }),
    []
  );

  const onDrop = useCallback(
    async (accepted, rejected) => {
      if (Object.keys(rejected).length !== 0) {
        if (onError) {
          onError();
        }
      } else {
        if (customOnChange) {
          customOnChange(accepted);
        } else if (onChange) {
          if (multiple) {
            let res = [];

            for (const file of accepted) {
              const value = await getBase64(file);

              const obj = {
                value,
                name: file.name,
                size: file.size,
                type: "storage",
              };

              if (includeFile) {
                obj.file = file;
              }

              res.push(obj);
            }

            onChange(res);
          } else {
            const file = accepted[0];
            const value = await getBase64(accepted[0]);

            onChange(
              {
                value,
                name: file.name,
                size: file.size,
                type: "storage",
              },
              includeFile ? file : undefined
            );
          }
        }
      }
    },
    [getBase64, customOnChange, multiple, onChange, onError, includeFile]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: !!multiple,
    accept,
    onDrop,
    ...props,
  });

  const hideOptionsVisibility = useCallback(
    () => setOptionsVisibility(false),
    []
  );

  const toggleOptionsVisibility = useCallback(
    () => setOptionsVisibility((prevState) => !prevState),
    []
  );

  useOutside(uploaderRef, hideOptionsVisibility);

  const handleGDriveSelect = useCallback(
    (data) => {
      if (data && data.length) {
        if (customOnChange) {
          customOnChange(data);
        } else if (onChange) {
          if (multiple) {
            let res = [];

            for (const file of data) {
              const obj = {
                value: file.url,
                name: file.name,
                size: file.sizeBytes,
                type: "link",
              };

              if (includeFile) {
                obj.file = file;
              }

              res.push(obj);
            }

            onChange(res);
          } else {
            const file = data[0];
            onChange(
              {
                value: file.url,
                name: file.name,
                size: file.sizeBytes,
                type: "link",
              },
              includeFile ? file : undefined
            );
          }
        }
      }
    },
    [multiple, onChange, includeFile, customOnChange]
  );

  const handleGDriveOption = useCallback(
    (e) => {
      e.nativeEvent.stopImmediatePropagation();

      if (
        !userIntegrations ||
        !userIntegrations.googleDriveApi ||
        !userIntegrations.googleDriveApi.data ||
        !userIntegrations.googleDriveApi.data.access_token
      ) {
        message.warning(
          "You need valid Google Drive integration. Please go to your Account Settings and connect Google Drive."
        );

        return;
      }

      try {
        initGoogleDrive(
          userIntegrations.googleDriveApi.data.access_token,
          handleGDriveSelect,
          acceptGDrive,
          !!multiple
        );

        setOptionsVisibility(false);
      } catch (err) {
        console.log(err);
        message.warning(err.message);
      }
    },
    [userIntegrations, handleGDriveSelect, multiple, acceptGDrive]
  );

  const renderFileList = useMemo(() => {
    if (showList && value) {
      if (multiple) {
        if (value.length) {
          return value.map(({ base64, file, name, id: fileId }, idx) => (
            <li key={`file-list-${name}-${idx}`}>
              <a
                href={base64 || file}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <FileFilled /> {name}
              </a>
              <FileListDeleter
                onClick={() =>
                  onDelete(id, idx, {
                    name,
                    file,
                    id: fileId,
                  })
                }
              >
                <DeleteOutlined />
              </FileListDeleter>
            </li>
          ));
        }
      } else {
        const { base64, file, name } = value;
        return (
          <li>
            <a
              href={base64 || file}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <FileFilled /> {name}
            </a>
            <FileListDeleter onClick={() => onDelete(id, value)}>
              <DeleteOutlined />
            </FileListDeleter>
          </li>
        );
      }
    }

    return null;
  }, [id, value, showList, multiple, onDelete]);

  return (
    <Fragment>
      {label ? <Label text={label} isRequired={isRequired} /> : null}
      <StyledWrapper
        $disabled={props.disabled ? true : false}
        $spacing={{
          mb: isError ? "0" : showList && renderFileList ? "7px" : "19px",
        }}
        {...wrapperOpts}
      >
        <DropzoneContainer {...getRootProps()}>
          <input
            {...getInputProps({
              id,
            })}
          />

          {customPlaceholder ? (
            customPlaceholder
          ) : (
            <Placeholder
              $spacing={{
                p: "10px",
              }}
              {...placeholderOpts}
            >
              <Fragment>
                {hidePlaceholderIcon ? null : placeholderIcon ? (
                  placeholderIcon
                ) : !multiple && value ? (
                  <FileDoneOutlined />
                ) : (
                  <FileAddOutlined />
                )}
                {hidePlaceholderText ? null : (
                  <p>
                    {placeholderText
                      ? placeholderText
                      : !multiple && value
                      ? "Uploaded"
                      : "Upload"}
                  </p>
                )}
                {onDelete && !props.disabled && value && !multiple ? (
                  <DeleterWrapper
                    $isRounded={wrapperOpts && wrapperOpts.$isRounded}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(id, value);
                    }}
                  >
                    <DeleteOutlined />
                  </DeleterWrapper>
                ) : null}
              </Fragment>
            </Placeholder>
          )}
        </DropzoneContainer>

        {!props.disabled && (!value || multiple) ? (
          <UploaderWrapper
            $isRounded={wrapperOpts && wrapperOpts.$isRounded}
            ref={uploaderRef}
          >
            <UploaderIcon onClick={toggleOptionsVisibility}>
              <PlusOutlined />
            </UploaderIcon>

            {optionsVisible && (
              <Dropdown
                $top="auto"
                $right="auto"
                $bottom="calc(100% + 5px)"
                $left="0"
                $bgColor={theme.colors.primaryLight}
              >
                <li
                  onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation();
                    open();
                  }}
                >
                  Upload File
                </li>
                {!disableGDrive && (
                  <li onClick={handleGDriveOption}>Google Drive</li>
                )}
              </Dropdown>
            )}
          </UploaderWrapper>
        ) : null}
      </StyledWrapper>
      {isError ? (
        <ErrorField
          $spacing={{
            mb: showList && renderFileList ? "7px" : "12px",
          }}
          {...errorOptions}
        >
          {errorMessage ? errorMessage : "Field is required"}
        </ErrorField>
      ) : null}
      {showList && renderFileList ? (
        <FileList
          $spacing={{
            mb: "12px",
          }}
          {...listOptions}
        >
          {renderFileList}
        </FileList>
      ) : null}
    </Fragment>
  );
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    userIntegrations: state.users.single.integrations,
  }),
  {
    fetchUserIntegrationsAction,
  }
)(Uploader);
