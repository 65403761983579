import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import ConfirmModal from "./components/ConfirmModal";
import CreateUserModal from "./components/CreateUserModal";
import Spinner from "./components/UI/Spinner";
import Routes from "./Routes";

import { authToken } from "./redux/actions/auth";

const App = ({ authToken, links }) => {
  const [isLoaded, editLoaded] = useState(false);

  useEffect(() => {
    window.Intercom("boot", {
      app_id: "qo9sn0m0",
    });

    if (window.localStorage.practiceToken) {
      async function tryLogin() {
        await authToken(window.localStorage.practiceToken, true);
        editLoaded(true);
      }
      tryLogin();
    } else {
      editLoaded(true);
    }

    return () => {
      window.Intercom("shutdown");
    };
  }, [authToken]);

  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {links.confirmator.visible && <ConfirmModal />}
      {links.users.visible && <CreateUserModal />}
      <Routes />
    </Fragment>
  );
};

export default connect(
  (state) => ({
    links: state.links,
  }),
  { authToken }
)(App);
