import React, { useMemo } from "react";
import Table from "../../UI/Table";
import StatusButton from "../../UI/StatusButton";
import CustomCell from "./CustomCell";

const tableColumns = [
  {
    header: "First Name",
    accessor: "firstName",
    minWidth: 150,
    enabled: 1,
    Cell: CustomCell,
    disableFilters: true,
  },
  {
    header: "Last Name",
    accessor: "lastName",
    minWidth: 150,
    enabled: 1,
    Cell: CustomCell,
    disableFilters: true,
  },
  {
    header: "Email",
    accessor: "email",
    minWidth: 150,
    enabled: 1,
  },
  {
    header: "Password",
    accessor: "password",
    minWidth: 150,
    enabled: 1,
    Cell: CustomCell,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    header: "School",
    accessor: "schoolId",
    minWidth: 150,
    enabled: 1,
    Cell: CustomCell,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    header: "Role",
    accessor: "roleId",
    minWidth: 150,
    enabled: 1,
    Cell: CustomCell,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    header: "Class",
    accessor: "classId",
    minWidth: 150,
    enabled: 1,
    Cell: CustomCell,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    header: "Class Role",
    accessor: "classRole",
    minWidth: 150,
    enabled: 1,
    Cell: CustomCell,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    header: "Practice User",
    accessor: "practiceUser",
    minWidth: 200,
    enabled: 1,
    disableFilters: true,
    disableSortBy: true,
  },
];

const ConfirmStep = ({
  data,
  onDataChange,
  isGA,
  isDA,
  addDisUsers,
  isDistrict,
  allRoles,
  allSchools,
  allClasses,
  schoolId,
  user,
}) => {
  const cellHandler = (field, val, email, index) => {
    onDataChange(field, val, email, index);
  };

  const schoolOpts = useMemo(() => {
    let res = [];
    if (allSchools && allSchools.length) {
      res = allSchools
        .filter((singleSchool) =>
          isDistrict
            ? true
            : parseInt(singleSchool.id, 10) === parseInt(schoolId, 10)
        )
        .map((singleSchool) => ({
          value: singleSchool.id,
          label: singleSchool.name,
        }));
    }

    if (isDistrict) {
      res.unshift({
        value: null,
        label: "None",
      });
    }

    return res;
  }, [allSchools, isDistrict, schoolId]);

  const tableData = useMemo(
    () =>
      data && data.length
        ? data.map((singleData) => {
            let statusText = "New user";
            if (singleData.inDistrict || singleData.inSchool) {
              statusText = "Already user";
            }

            if (singleData.error) {
              if (singleData.error.class) {
                statusText += ". Class invalid.";
              }

              if (singleData.error.classRole) {
                statusText += ". Class role invalid.";
              }

              if (singleData.error.email) {
                statusText = "Invalid email.";
              }

              if (singleData.error.user) {
                statusText = singleData.error.user;
              }
            }

            return {
              ...singleData,
              firstName: singleData.firstName,
              lastName: singleData.lastName,
              email: singleData.email,
              password: singleData.password ? singleData.password : "",
              schoolId: singleData.schoolId,
              roleId: singleData.roleId,
              practiceUser: (
                <StatusButton
                  text={statusText}
                  $warning={
                    singleData.error &&
                    (singleData.error.user || singleData.error.email)
                      ? true
                      : false
                  }
                  $success={
                    (!singleData.error ||
                      (!singleData.error.user &&
                        !singleData.error.email &&
                        !singleData.error.class &&
                        !singleData.error.classRole)) &&
                    !singleData.inDistrict &&
                    !singleData.inSchool
                      ? true
                      : false
                  }
                />
              ),
            };
          })
        : [],
    [data]
  );

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      customCellData={{
        schoolOpts,
        allRoles,
        allClasses,
        cellHandler,
        isDistrict,
        isGA,
        isDA,
        addDisUsers,
        user,
      }}
      skipPageReset
    />
  );
};

export default ConfirmStep;
