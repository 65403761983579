import { combineReducers } from "redux";
import auth from "./auth";
import links from "./links";
import structures from "./structures";
import schools from "./Schools";
import classes from "./Classes";
import courses from "./Courses";
import users from "./Users";
import roles from "./Roles";
import tags from "./Tags";
import terms from "./Terms";
import communication from "./Communication";
import districts from "./Districts";

export default combineReducers({
  auth,
  links,
  structures,
  schools,
  classes,
  courses,
  users,
  roles,
  tags,
  terms,
  communication,
  districts,
});
