import {
  FETCH_DISTRICT_COMMENTS,
  UPDATE_DISTRICT_COMMENT,
  DELETE_DISTRICT_COMMENT,
  RESET_DISTRICT_DATA,
} from "../../../types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DISTRICT_COMMENTS: {
      return [...payload];
    }
    case UPDATE_DISTRICT_COMMENT: {
      let newList = [...state],
        idx = newList.findIndex((singleEl) => singleEl.id === payload.id);

      if (idx > -1) {
        newList.splice(idx, 1, {
          ...newList[idx],
          ...payload,
        });
      }
      return newList;
    }
    case DELETE_DISTRICT_COMMENT: {
      let newList = [...state],
        idx = newList.findIndex((singleEl) => singleEl.id === payload);

      if (idx > -1) {
        newList.splice(idx, 1);
      }
      return newList;
    }
    case RESET_DISTRICT_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
