import { SET_SINGLE_SCHOOL, UPDATE_SINGLE_SCHOOL } from "../../../types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SINGLE_SCHOOL: {
      return {
        ...payload,
      };
    }
    case UPDATE_SINGLE_SCHOOL: {
      if (payload && payload.id === state.id) {
        return {
          ...state,
          ...payload,
        };
      }

      return state;
    }
    default:
      return state;
  }
}
