import styled from "styled-components";
import { spacing } from "../../Theme/styledOptions";

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: ${(props) => props.theme.zIndex.modal};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledContent = styled.div`
  flex-basis: ${(props) => (props.$width ? props.$width : "500px")};
  max-width: calc(100% - 60px);
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  overflow-x: auto;
  max-height: calc(100vh - 60px);
  ${spacing};
`;

export const StyledCloser = styled.div`
  position: absolute;
  top: calc(50% - 12.5px);
  right: 0;

  > span {
    background-color: ${(props) => props.theme.colors.primary};
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 25px;
    text-align: center;
    line-height: 23px;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${(props) => props.theme.colors.warning};
    }
  }
`;

export const StyledHeader = styled.div`
  position: relative;
  padding: 0 35px;

  > h2,
  > h3,
  > h4 {
    text-align: center;
  }
`;

export const StyledFooter = styled.div`
  text-align: center;
`;

export default StyledWrapper;
