import React, { Fragment } from "react";

import StyledWrapper, { ErrorField } from "./StyledWrapper";
import Label from "../Label";
import PropTypes from "prop-types";

const Input = ({
  name,
  id,
  onChange,
  label,
  type = "text",
  value,
  placeholder,
  errorOptions,
  errorMessage,
  isError,
  isRequired,
  isDisabled,
  customCursor,
  inputProps,
  ...rest
}) => {
  return (
    <Fragment>
      {label ? (
        <Label isRequired={isRequired} text={label} htmlFor={id} />
      ) : null}
      <StyledWrapper
        $spacing={{
          mb: isError ? "0" : "12px",
        }}
        $isDisabled={isDisabled ? true : false}
        $customCursor={customCursor}
        {...rest}
      >
        <input
          value={value}
          type={type}
          name={name}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          disabled={isDisabled ? true : false}
          {...inputProps}
        />
      </StyledWrapper>
      {isError ? (
        <ErrorField
          $spacing={{
            mb: "12px",
          }}
          {...errorOptions}
        >
          {errorMessage ? errorMessage : "Field is required"}
        </ErrorField>
      ) : null}
    </Fragment>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
};

export default Input;
