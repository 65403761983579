import { FETCH_USER_INTEGRATIONS } from "../../types";

import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

// User Integrations
export const fetchUserIntegrationsAction = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/users/${userId}/integrations`, config);

    dispatch({
      type: FETCH_USER_INTEGRATIONS,
      payload: {
        userId: parseInt(userId, 10),
        data: res.data,
      },
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error ocurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

/**
 * @param {*} type - one of googleMeetApi, zoomApi, teamsApi
 */
export const connectUserOAuthIntAction = async (userId, type) => {
  try {
    const res = await axios.get(
      `/users/${userId}/integrations/oauth/${type}`,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error ocurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const syncUserOAuthIntAction = async (userId, type, code) => {
  const body = JSON.stringify({ code });
  try {
    const res = await axios.post(
      `/users/${userId}/integrations/oauth/${type}`,
      body,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error ocurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const deleteUserOAuthIntAction = async (userId, name) => {
  try {
    const res = await axios.delete(
      `/users/${userId}/integrations/${name}`,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error ocurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};
