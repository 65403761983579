export function spacing({ $spacing }) {
  if (!$spacing) {
    return null;
  }

  let styles = {};

  // paddings
  const { p, px, py, pl, pr, pb, pt } = $spacing;

  // margins
  const { m, mx, my, ml, mr, mb, mt } = $spacing;

  // paddings
  if (px) {
    styles.paddingLeft = px;
    styles.paddingRight = px;
  }

  if (py) {
    styles.paddingTop = py;
    styles.paddingBottom = py;
  }

  if (pl) {
    styles.paddingLeft = pl;
  }

  if (pr) {
    styles.paddingRight = pr;
  }

  if (pb) {
    styles.paddingBottom = pb;
  }

  if (pt) {
    styles.paddingTop = pt;
  }

  if (p) {
    styles.padding = p;
  }

  // margins
  if (mx) {
    styles.marginLeft = mx;
    styles.marginRight = mx;
  }

  if (my) {
    styles.marginTop = my;
    styles.marginBottom = my;
  }

  if (ml) {
    styles.marginLeft = ml;
  }

  if (mr) {
    styles.marginRight = mr;
  }

  if (mb) {
    styles.marginBottom = mb;
  }

  if (mt) {
    styles.marginTop = mt;
  }

  if (m) {
    styles.margin = m;
  }

  return styles;
}

export function colors({ $color, $bg, $hoverColor, $hoverBg }) {
  let styles = {};
  if ($color) {
    styles.color = $color;
  }

  if ($bg) {
    styles.backgroundColor = $bg;
  }

  // hover
  if ($hoverColor) {
    styles[":hover"] = {
      color: $hoverColor,
    };
  }

  if ($hoverBg) {
    styles[":hover"] = {
      ...styles[":hover"],
      backgroundColor: $hoverBg,
    };
  }
  return styles;
}

export function flex({
  $displayFlex,
  $flex,
  $justifyContent,
  $alignItems,
  $flexWrap,
}) {
  let styles = {};

  if (typeof $displayFlex === "boolean" && $displayFlex) {
    styles.display = "flex";
  }

  if ($flex) {
    styles.flex = $flex;
  }

  if ($justifyContent) {
    styles.justifyContent = $justifyContent;
  }

  if ($alignItems) {
    styles.alignItems = $alignItems;
  }

  if ($flexWrap) {
    styles.flexWrap = $flexWrap;
  }

  return styles;
}

export function textAlignment({ $textAlign }) {
  if ($textAlign) {
    return { $textAlign };
  }
  return null;
}

export default {
  spacing,
  textAlignment,
  flex,
  colors,
};
