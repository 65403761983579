import { gapi } from "gapi-script";

export const initGoogleDrive = (access_token, onSelect, accept, isMulti) => {
  if (access_token) {
    gapi.load("picker", function () {
      const google = window.google;
      const view = new google.picker.View(google.picker.ViewId.DOCS);
      view.setMimeTypes(accept);
      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .setAppId(process.env.REACT_APP_GOOGLE_DRIVE_PROJECT_ID)
        .setOAuthToken(access_token)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_DRIVE_API_KEY)
        .setCallback((data) => {
          if (data.action === google.picker.Action.PICKED) {
            onSelect(data.docs);
          }
        });

      if (isMulti) {
        picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
      }

      picker.build().setVisible(true);
    });
  } else {
    throw new Error("Valid access token is needed.");
  }
};

export default initGoogleDrive;
