import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ auth, component: Component, ...routeProps }) => {
  const isAuthenticated = auth.token && auth.user && auth.user.id;

  return (
    <Route
      {...routeProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  null
)(ProtectedRoute);
