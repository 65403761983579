import { FETCH_DISTRICTS, UPDATE_DISTRICT, DELETE_DISTRICT } from "../../types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DISTRICTS: {
      return [...payload];
    }
    case UPDATE_DISTRICT: {
      let newList = [...state],
        idx = newList.findIndex((singleEl) => singleEl.id === payload.id);

      if (idx > -1) {
        newList.splice(idx, 1, {
          ...newList[idx],
          ...payload,
        });
      }
      return newList;
    }
    case DELETE_DISTRICT: {
      let newList = [...state],
        idx = newList.findIndex((singleEl) => singleEl.id === payload);

      if (idx > -1) {
        newList.splice(idx, 1);
      }
      return newList;
    }
    default:
      return state;
  }
}
