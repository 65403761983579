import { darken } from "polished";
import styled from "styled-components";

const StyledWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 3px 0;
  position: absolute;
  top: ${(props) => (props.$top ? props.$top : "100%")};
  right: ${(props) => (props.$right ? props.$right : "0")};
  bottom: ${(props) => (props.$bottom ? props.$bottom : "")};
  left: ${(props) => (props.$left ? props.$left : "")};
  background-color: ${(props) =>
    props.$bgColor ? props.$bgColor : props.theme.colors.primaryLightest};
  color: ${(props) => (props.$color ? props.$color : "#000000")};
  min-width: ${(props) => (props.$minWidth ? props.$minWidth : "150px")};
  border-radius: 4px;

  li {
    padding: 7px 14px;
    cursor: pointer;
    line-height: 1.2;
    font-size: 12px;

    &:hover {
      background-color: ${(props) =>
        darken(
          0.1,
          props.$bgColor ? props.$bgColor : props.theme.colors.primaryLightest
        )};
    }
  }
`;

export default StyledWrapper;
