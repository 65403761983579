import React from "react";
import { Alert } from "antd";

const NotAuthorized = ({ message }) => {
  return (
    <Alert
      message={
        message
          ? message
          : "Not authorized. Please contact PRACTICE Software support."
      }
      type="error"
    />
  );
};

export default NotAuthorized;
