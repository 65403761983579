import styled from "styled-components";
import { spacing } from "../../Theme/styledOptions";
import { lighten } from "polished";

const StyledWrapper = styled.div`
  display: inline-block;
  padding: 5px 9px;
  text-align: center;
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) =>
    props.bgColor
      ? props.bgColor
      : props.$success
      ? lighten(0.35, props.theme.colors.darkGreen)
      : props.$warning
      ? lighten(0.3, props.theme.colors.warning)
      : lighten(0.3, props.theme.colors.primary)};
  border-color: ${(props) =>
    props.borderColor
      ? props.borderColor
      : props.$success
      ? props.theme.colors.success
      : props.$warning
      ? props.theme.colors.warning
      : props.theme.colors.primary};
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) =>
    props.borderColor
      ? props.borderColor
      : props.$success
      ? props.theme.colors.darkGreen
      : props.$warning
      ? props.theme.colors.warning
      : props.theme.colors.primary};
  ${spacing};
`;

export default StyledWrapper;
