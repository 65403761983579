import styled from "styled-components";

export const ListItemActionEmail = styled.span`
  font-size: 12px;
  color: 1px solid ${(props) => props.theme.colors.darkGrey};
  margin-right: 10px;
`;

export const ListItemAction = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.colors.primaryLightest};
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) =>
    props.$showBgColor ? props.theme.colors.primaryLightest : "transparent"};
  padding: 0 5px;
  margin-bottom: 5px;

  > *:not(${ListItemAction}) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const OverviewStepList = styled.ul`
  list-style-type: none;
  margin: 0 0 15px;
  padding: 0;
`;

export default OverviewStepList;
