import { FETCH_COURSES, UPDATE_COURSE, DELETE_COURSE } from "../../types";

const initialState = {
  params: null,
  data: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_COURSES: {
      return {
        params: payload.params
          ? {
              ...payload.params,
            }
          : null,
        data: payload.data && payload.data.length ? [...payload.data] : [],
      };
    }
    case UPDATE_COURSE: {
      let newData = [...state.data],
        idx = newData.findIndex((singleEl) => singleEl.id === payload.id);

      if (idx > -1) {
        newData.splice(idx, 1, {
          ...payload,
        });
      }
      return {
        ...state,
        data: newData,
      };
    }
    case DELETE_COURSE: {
      let newData = [...state.data],
        idx = newData.findIndex((singleEl) => singleEl.id === payload);

      if (idx > -1) {
        newData.splice(idx, 1);
      }
      return {
        ...state,
        data: newData,
      };
    }
    default:
      return state;
  }
}
