import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";

import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import store from "./redux";
import theme from "./components/Theme";
import axios from "axios";

import "./styles/antd.global.less";
import "./styles/index.global.scss";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.render(
  <ErrorBoundary>
    <ConfigProvider
      getPopupContainer={(node) => {
        if (node) {
          return node.parentNode;
        }
        return document.body;
      }}
    >
      <Provider store={store}>
        <Router>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Router>
      </Provider>
    </ConfigProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
