import { combineReducers } from "redux";

import info from "./info";
import integrations from "./integrations";
import gradebook from "./gradebook";
import comments from "./comments";
import anecdotals from "./anecdotals";

export default combineReducers({
  info,
  gradebook,
  comments,
  anecdotals,
  integrations,
});
