const BREAKPOINTS = {
  xxs: "576px",
  xs: "768px",
  sm: "992px",
  md: "1200px", // default
  lg: "1360px",
  xl: "1920px",
};

function up(breakpoint) {
  const size = BREAKPOINTS[breakpoint] || BREAKPOINTS["md"];
  return `@media screen and (min-width: calc(${size} + 1px))`;
}

function down(breakpoint) {
  const size = BREAKPOINTS[breakpoint] || BREAKPOINTS["md"];
  return `@media screen and (max-width: ${size})`;
}

function between(breakpoint1, breakpoint2) {
  const size1 = BREAKPOINTS[breakpoint1] || BREAKPOINTS["md"];
  const size2 = BREAKPOINTS[breakpoint2] || BREAKPOINTS["md"];
  if (breakpoint2) {
    return `@media screen and (min-width: ${size1}) and (max-width: ${size2})`;
  }
  return `@media screen and (max-width: ${size1})`;
}

function only(breakpoint) {
  switch (breakpoint) {
    case "xs":
      return between("xs", "sm");
    case "sm":
      return between("sm", "md");
    case "lg":
      return between("lg", "xl");
    case "xl":
      return between("xl");
    default:
      return between("md", "lg");
  }
}

const theme = {
  boxShadow: "0 0 4px #e5e5e5",
  borderRadius: "12px",
  heights: {
    small: "24px",
    middle: "32px", // default
    large: "40px",
  },
  colors: {
    primary: "#4760f3",
    primaryLight: "#e3e7fd",
    primaryLighter: "#eff2ff",
    primaryLightest: "#f8f9fe",
    boxShadow: "#e5e5e5",
    formBorder: "#e6e6e6",
    bodyText: "#323233",
    blue: "#4760f3",
    green: "#52c41a",
    darkGreen: "#44995F",
    red: "#ff4d4f",
    grey: "#e8ebf1",
    darkGrey: "#89969F",
    success: "#52c41a",
    warning: "#ff4d4f",
    disabled: "#ececec",
  },
  typography: {
    fontWeight: "400",
    h1: "2rem",
    h2: "1.8rem",
    h3: "1.6rem",
    h4: "1.4rem",
    h5: "1.2rem",
    h6: "0.8rem",
    body: "1rem",
  },
  spacing: (...args) => {
    const sizes = [0, 4, 8, 16, 32, 64];
    let pxs = [];

    args.forEach((idx) => {
      if (sizes[idx] !== undefined) {
        pxs.push(`${sizes[idx]}px`);
      } else if (typeof idx === "string") {
        pxs.push(idx);
      }
    });

    return pxs.join(" ");
  },
  breakpoints: {
    up,
    down,
    between,
    only,
  },
  zIndex: {
    modal: 2,
    linkFilterer: 2,
  },
};

export default theme;
