import React, { Fragment } from "react";

import { Button } from "antd";
import UserAvatarText from "../../UI/UserAvatarText";
import OverviewStepList, {
  ListItem,
  ListItemAction,
  ListItemActionEmail,
} from "./OverviewStepList";

const OverviewStep = ({
  skippedUsers,
  uploadedUsers,
  districtId,
  isDistrict,
}) => {
  return (
    <Fragment>
      {uploadedUsers && uploadedUsers.length ? (
        <Fragment>
          <p className="text-center">
            You have successfully added{" "}
            {uploadedUsers ? uploadedUsers.length : 0} new users.
          </p>
          <h3>Uploaded</h3>
          <OverviewStepList>
            {uploadedUsers.map((singleUser) => (
              <ListItem key={singleUser.id}>
                <UserAvatarText
                  {...singleUser}
                  wrapperOptions={{
                    $isSmall: true,
                  }}
                  hideEmail
                />
                <ListItemAction>
                  <ListItemActionEmail>{singleUser.email}</ListItemActionEmail>
                  <Button
                    shape="round"
                    onClick={() =>
                      window
                        .open(
                          isDistrict
                            ? `/districts/${districtId}/users/${singleUser.id}`
                            : `/dashboard/users/${singleUser.id}`,
                          "_blank"
                        )
                        .focus()
                    }
                  >
                    Visit Profile
                  </Button>
                </ListItemAction>
              </ListItem>
            ))}
          </OverviewStepList>
        </Fragment>
      ) : null}

      {skippedUsers && skippedUsers.length ? (
        <Fragment>
          <p className="text-center">
            Users in the list below have been skipped because they have already
            been added to your school or there was an error.
          </p>
          <h3>Skipped</h3>
          <OverviewStepList>
            {skippedUsers.map((singleUser) => (
              <ListItem key={singleUser.id} $showBgColor>
                <UserAvatarText
                  {...singleUser}
                  wrapperOptions={{
                    $isSmall: true,
                  }}
                  hideEmail
                />
                <ListItemAction>
                  <ListItemActionEmail>{singleUser.email}</ListItemActionEmail>
                  {singleUser.error &&
                  (singleUser.error.user || singleUser.error.email) ? null : (
                    <Button
                      shape="round"
                      onClick={() =>
                        window
                          .open(
                            isDistrict
                              ? `/districts/${districtId}/users/${singleUser.id}`
                              : `/dashboard/users/${singleUser.id}`,
                            "_blank"
                          )
                          .focus()
                      }
                    >
                      Visit Profile
                    </Button>
                  )}
                </ListItemAction>
              </ListItem>
            ))}
          </OverviewStepList>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default OverviewStep;
