import React from "react";
import StyledWrapper from "./StyledWrapper";

const UserAvatarText = ({ wrapperOptions, ...props }) => {
  return (
    <StyledWrapper {...wrapperOptions} className="user-avatar-text">
      <div
        className="user-avatar-text__image"
        style={{ backgroundImage: props.photo ? `url(${props.photo})` : "" }}
      >
        {props.photo
          ? null
          : props.firstName && props.lastName
          ? `${props.firstName.slice(0, 1)}${props.lastName.slice(0, 1)}`
          : props.email.slice(0, 2)}
      </div>
      <div className="user-avatar-text__info">
        {props.firstName && props.lastName ? (
          <h6>{`${props.firstName} ${props.lastName}`}</h6>
        ) : null}
        {props.hideEmail ? null : (
          <span className="user-avatar-text__email">{props.email}</span>
        )}
      </div>
    </StyledWrapper>
  );
};

export default UserAvatarText;
