import styled, { css } from "styled-components";
import { spacing } from "../../Theme/styledOptions";

export const ErrorField = styled.div`
  color: ${(props) => props.theme.colors.warning};
  font-size: 12px;
  ${spacing}
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: none;
  cursor: pointer;
  flex: 1;
`;

export const Element = styled.span`
  position: relative;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: inline-block;
  background-color: ${(props) => props.theme.colors.primaryLight};

  &::before {
    content: "";
    background-color: ${(props) =>
      props.$isChecked ? props.theme.colors.primary : "transparent"};
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
  }
`;

export const Text = styled.span`
  margin-left: 7px;
`;

export const ExtraInfo = styled.span`
  margin-left: auto;
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 12px;
`;

const slider = css`
  ${Element} {
    border-radius: 15px;
    min-height: 17px;
    min-width: 30px;
    background-color: ${(props) =>
      props.$isChecked ? props.theme.colors.primary : props.theme.colors.grey};

    &::before {
      background-color: #fff;
      width: 11px;
      height: 11px;
      top: 50%;
      left: ${(props) => (props.$isChecked ? "auto" : "3px")};
      right: ${(props) => (props.$isChecked ? "3px" : "auto")};
      transform: translateY(-50%);
    }
  }
`;

const fullWidth = css`
  padding-left: 11px;
  padding-right: 11px;
  background-color: ${(props) => props.theme.colors.primaryLightest};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid
    ${(props) =>
      props.$isChecked ? props.theme.colors.primary : "transparent"};
  cursor: pointer;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
    ${Element} {
      &::before {
        background-color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: ${(props) =>
    props.$size && props.theme.heights[props.$size]
      ? props.theme.heights[props.$size]
      : props.theme.heights.middle};
  ${spacing}

  ${(props) => (props.$look && props.$look === "slider" ? slider : null)}
  ${(props) => (props.$look && props.$look === "fullWidth" ? fullWidth : null)}

  input {
    display: none;
  }
`;

export default StyledWrapper;
