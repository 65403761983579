import {
  FETCH_CLASSES,
  DELETE_SINGLE_CLASS,
  UPDATE_SINGLE_CLASS,
  OPEN_CLASS_CODE_MODAL,
  CLOSE_CLASS_CODE_MODAL,
} from "../../types";

import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const fetchDisClassesAction =
  (disId, stopDispatch) => async (dispatch) => {
    try {
      const res = await axios.get(`/districts/${disId}/classes`, config);

      if (!stopDispatch) {
        dispatch({
          type: FETCH_CLASSES,
          payload: {
            params: {
              districtId: disId,
            },
            data: res.data,
          },
        });
      }

      return res.data;
    } catch (err) {
      if (err.response) {
        if (err.response.status === 403) {
          let error = {
            message: "Not Authorized",
            status: 403,
          };
          throw error;
        } else {
          throw new Error("An error occurred. Please try again later..");
        }
      } else {
        throw new Error(
          "Network error - Please check your network connection or firewall settings and try again."
        );
      }
    }
  };

export const fetchClassesAction =
  (schoolId, stopDispatch) => async (dispatch) => {
    try {
      const res = await axios.get(`/schools/${schoolId}/classes`, config);

      if (!stopDispatch) {
        dispatch({
          type: FETCH_CLASSES,
          payload: {
            params: {
              schoolId: schoolId ? schoolId : undefined,
            },
            data: res.data,
          },
        });
      }

      return res.data;
    } catch (err) {
      if (err.response) {
        if (err.response.status === 403) {
          let error = {
            message: "Not Authorized",
            status: 403,
          };
          throw error;
        } else {
          throw new Error("An error occurred. Please try again later..");
        }
      } else {
        throw new Error(
          "Network error - Please check your network connection or firewall settings and try again."
        );
      }
    }
  };

export const fetchSingleClassAction = async (id) => {
  try {
    const res = await axios.get(`/classes/${id}`, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const createClassAction = async (data, schoolId) => {
  const body = JSON.stringify({ ...data });

  try {
    await axios.post(`/${schoolId}/classes`, body, config);
  } catch (err) {
    if (err.response) {
      throw new Error("Could not create class. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateClassAction = (data, stopDispatch) => async (dispatch) => {
  const body = JSON.stringify({ ...data });

  try {
    await axios.put(`/classes/${data.id}`, body, config);
    if (!stopDispatch) {
      dispatch({
        type: UPDATE_SINGLE_CLASS,
        payload: data,
      });
    }
  } catch (err) {
    if (err.response) {
      throw new Error("Could not update the class. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const deleteClassAction = (id) => async (dispatch) => {
  try {
    await axios.delete(`/classes/${id}`, config);
    dispatch({
      type: DELETE_SINGLE_CLASS,
      payload: id,
    });
  } catch (err) {
    if (err.response) {
      throw new Error("Could not delete the class. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateClassUsers = async (users, classId) => {
  const body = JSON.stringify(users);

  try {
    let res = await axios.post(`/classes/${classId}/participant`, body, config);
    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("Could not update users. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const deleteClassUser = async (userId, classId) => {
  try {
    await axios.delete(`/classes/${classId}/participant/${userId}`, config);
  } catch (err) {
    if (err.response) {
      throw new Error("Could not delete the user. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Class Invites

export const refreshClassUsersCodes = async (classId) => {
  try {
    let res = await axios.put(
      `/classes/${classId}/regenerate-codes`,
      undefined,
      config
    );
    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error(
        "Could not generate new invite links. Please try again later.."
      );
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Class Periods

export const fetchClassPeriodsAction = async (id) => {
  try {
    const res = await axios.get(`/classes/${id}/periods`, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Class Gradebook

export const fetchClassGBookAction = async (id, periodId) => {
  try {
    const res = await axios.get(
      `/classes/${id}/gradebooks/${periodId}`,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateClassGBookAction = async (id, data) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(`/classes/${id}/gradebooks`, body, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const overwriteClassGBookAction = async (id, data) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(
      `/classes/${id}/gradebooks/overall`,
      body,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const sendClassGBookCommentAction = async (id, data) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(
      `/classes/${id}/gradebooks/comments`,
      body,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const deleteClassGBookCommentAction = async (classId, id) => {
  try {
    const res = await axios.delete(
      `/classes/${classId}/gradebooks/comments/${id}`,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Class Gradebook Settings

export const fetchClassGBookSettingsAction = async (id) => {
  try {
    const res = await axios.get(`/classes/${id}/gradebookSettings`, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateClassGBookSettingsAction = async (id, data) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(
      `/classes/${id}/gradebookSettings`,
      body,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Class Users Clever

export const fetchClassCleverUsers = async (classId) => {
  try {
    const res = await axios.get(`/classes/${classId}/clever`, config);

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateClassCleverUsers = async (classId, users) => {
  const body = JSON.stringify(users);

  try {
    let res = await axios.post(`/classes/${classId}/clever`, body, config);
    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("Could not update users. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

// Join Class By Code Modal

export const openClassCodeModalAction = () => ({
  type: OPEN_CLASS_CODE_MODAL,
});

export const closeClassCodeModalAction = () => ({
  type: CLOSE_CLASS_CODE_MODAL,
});
