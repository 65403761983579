import React, { Fragment } from "react";

import StyledWrapper, {
  Label,
  Element,
  Text,
  ExtraInfo,
  ErrorField,
} from "./StyledWrapper";
import MainLabel from "../Label";
import PropTypes from "prop-types";

const Checkbox = ({
  isChecked,
  name,
  id,
  onChange,
  mainLabel,
  label,
  type = "checkbox",
  look = "normal", // slider, fullWidth
  value,
  extraInfo,
  hideElement,
  errorOptions,
  errorMessage,
  isError,
  isRequired,
  ...rest
}) => {
  return (
    <Fragment>
      {mainLabel ? (
        <MainLabel isRequired={isRequired} text={mainLabel} htmlFor={id} />
      ) : null}
      <StyledWrapper
        $look={look}
        $isChecked={isChecked ? isChecked : false}
        $spacing={{
          mb: isError ? "0" : "12px",
        }}
        onClick={() =>
          look === "fullWidth" && onChange ? onChange(name, value) : null
        }
        {...rest}
      >
        <input
          checked={isChecked ? isChecked : false}
          value={value}
          type={type}
          name={name}
          id={id}
          onChange={() =>
            look !== "fullWidth" && onChange ? onChange(name, value) : null
          }
          onClick={(e) => e.stopPropagation()}
        />
        <Label htmlFor={id}>
          {hideElement ? null : (
            <Element $isChecked={isChecked ? isChecked : false} />
          )}
          {label ? <Text>{label}</Text> : null}
          {extraInfo ? <ExtraInfo>{extraInfo}</ExtraInfo> : null}
        </Label>
      </StyledWrapper>
      {isError ? (
        <ErrorField
          $spacing={{
            mb: "12px",
          }}
          {...errorOptions}
        >
          {errorMessage ? errorMessage : "Field is required"}
        </ErrorField>
      ) : null}
    </Fragment>
  );
};

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  hideElement: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  look: PropTypes.string,
};

export default Checkbox;
