import { useEffect } from "react";

const useOutside = (ref, callback, capture) => {
  useEffect(() => {
    const handleClickOutside = (evt) => {
      if (ref.current && !ref.current.contains(evt.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClickOutside, capture);
    return () => {
      document.removeEventListener("click", handleClickOutside, capture);
    };
  }, [ref, callback, capture]);
};

export default useOutside;
