import React from "react";

import StyledWrapper, { Text } from "./StyledWrapper";
import { LoadingOutlined } from "@ant-design/icons";

const Button = ({
  htmlType = "button",
  type, // primary, light, warning, connection
  size,
  disabled,
  isBlock,
  isFlat,
  isRounded,
  isLoading,
  isLoadingOnly,
  prependElement,
  appendElement,
  hideTextMobile,
  children,
  ...rest
}) => {
  return (
    <StyledWrapper
      type={htmlType}
      $type={type}
      $size={size}
      $isBlock={!!isBlock}
      $isFlat={!!isFlat}
      $isRounded={!!isRounded}
      $isDisabled={!!disabled}
      disabled={!!disabled || !!isLoading || !!isLoadingOnly}
      {...rest}
    >
      {isLoading || isLoadingOnly ? (
        <span>
          <LoadingOutlined />
        </span>
      ) : prependElement ? (
        <span>{prependElement}</span>
      ) : null}

      {!isLoadingOnly && children && (
        <Text
          $hideTextMobile={!!hideTextMobile}
          $isConnection={type === "connection"}
        >
          {children}
        </Text>
      )}

      {!isLoadingOnly && appendElement && <span>{appendElement}</span>}
    </StyledWrapper>
  );
};

export default Button;
