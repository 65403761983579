import React, { forwardRef } from "react";

import DropdownWrapper from "./StyledWrapper";

const Dropdown = forwardRef(({ children, ...rest }, ref) => {
  if (!children) {
    return null;
  }

  return (
    <DropdownWrapper {...rest} ref={ref}>
      {children}
    </DropdownWrapper>
  );
});

export default Dropdown;
