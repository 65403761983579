import React, { useState, useMemo, useEffect, Fragment } from "react";

import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  useExpanded,
} from "react-table";
import { Empty } from "antd";

import TableFilterer from "./Filterer";
import Pagination from "./Pagination";
import TableColumnSelector from "./ColumnSelector";

import { ExpandOutlined, CompressOutlined } from "@ant-design/icons";

import StyledWrapper, {
  TableCellExpander,
  TableHeader,
  TableHeaderSort,
  TableHeaderSortIco,
  CustomRow,
} from "./StyledWrapper";

const Table = ({
  wrapperOptions,
  topFlat,
  bottomFlat,
  isExpandable,
  showSelector,
  fixedRows,
  skipPageReset,
  pageIndex: propsPageIdx,
  pageSize: propsPageSize,
  handlePageChange,
  handlePageSizeChange,
  data,
  columns,
  ...props
}) => {
  const [cmpState, editState] = useState({
    hiddenColumns: [],
  });

  const { hiddenColumns } = cmpState;

  const filteredColumns = useMemo(() => {
    if (!columns || !columns.length) {
      return [];
    }

    let result = columns
      .filter((record) => record.enabled)
      .map((record) => {
        let updated = {
          Header: record.header,
          accessor: record.accessor,
          disableFilters: record.disableFilters,
          Filter: !record.disableFilters ? TableFilterer : undefined,
          filter: !record.disableFilters ? "equals" : undefined,
          disableSortBy: record.disableSortBy ? record.disableSortBy : false,
          sortType: record.sortMethod ? record.sortMethod : "alphanumeric",
          minWidth: record.minWidth,
          customWidth: record.customWidth,
          cellClass:
            record.cellClass || record.sticky
              ? `${record.cellClass ? record.cellClass : ""} ${
                  record.sticky
                    ? `sticky-cell sticky-cell--${record.sticky}`
                    : ""
                }`
              : undefined,
          headerClass:
            record.headerClassName || record.sticky
              ? `${record.headerClassName ? record.headerClassName : ""} ${
                  record.sticky
                    ? `sticky-cell sticky-cell--${record.sticky}`
                    : ""
                }`
              : undefined,
        };

        if (record.Cell) {
          updated.Cell = record.Cell;
        }

        return updated;
      });

    if (isExpandable) {
      result.unshift({
        id: "columnExpander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <ExpandOutlined /> : <CompressOutlined />}
          </span>
        ),
        Cell: ({ row }) =>
          row.canExpand ? (
            <div
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              <TableCellExpander $isExpanded={row.isExpanded ? true : false}>
                {row.subRows ? row.subRows.length : ""}
              </TableCellExpander>
            </div>
          ) : null,
      });
    }

    if (showSelector) {
      result.push({
        id: "columnPicker",
        Header: (
          <TableColumnSelector
            allColumns={columns}
            hiddenColumns={hiddenColumns}
            onChange={(hiddenColumns) =>
              editState((prevState) => ({
                ...prevState,
                hiddenColumns,
              }))
            }
          />
        ),
        minWidth: 50,
      });
    }

    return result;
  }, [columns, hiddenColumns, isExpandable, showSelector]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize, pageIndex },
  } = useTable(
    {
      columns: filteredColumns,
      data,
      initialState: {
        pageSize: propsPageSize ? propsPageSize : 10,
        pageIndex: propsPageIdx || propsPageIdx === 0 ? propsPageIdx : 0,
      },
      customCellUpdater: props.customCellUpdater
        ? props.customCellUpdater
        : () => {},
      customCellData: props.customCellData ? props.customCellData : undefined,
      autoResetPage: !skipPageReset,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(
    () => {
      if (skipPageReset && pageIndex && pageIndex >= pageCount) {
        gotoPage(pageCount ? pageCount - 1 : 0);
      }
    },
    // eslint-disable-next-line
    [pageCount, pageIndex]
  );

  useEffect(
    () => {
      if (
        (propsPageIdx || propsPageIdx === 0) &&
        propsPageIdx !== pageIndex &&
        propsPageIdx < pageCount
      ) {
        gotoPage(propsPageIdx);
      }
    },
    // eslint-disable-next-line
    [propsPageIdx]
  );

  useEffect(
    () => {
      if (propsPageSize && propsPageSize !== pageSize) {
        setPageSize(propsPageSize);
      }
    },
    // eslint-disable-next-line
    [propsPageSize]
  );

  useEffect(
    () => {
      if (pageIndex !== propsPageIdx && handlePageChange) {
        handlePageChange(pageIndex);
      }
    },
    // eslint-disable-next-line
    [pageIndex]
  );

  useEffect(
    () => {
      if (pageSize !== propsPageSize && handlePageSizeChange) {
        handlePageSizeChange(pageSize);
      }
    },
    // eslint-disable-next-line
    [pageSize]
  );

  const renderPage = useMemo(() => {
    const res =
      page && page.length
        ? page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps([
                  {
                    "data-id": row.original.id,
                    onClick: () => {
                      if (row.original && row.original.rowClick) {
                        row.original.rowClick();
                      }
                    },
                  },
                ])}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.cellClass,
                          style: {
                            minWidth: cell.column.minWidth
                              ? cell.column.minWidth
                              : "",
                            width: cell.column.customWidth
                              ? cell.column.customWidth
                              : "",
                          },
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })
        : [];

    if (fixedRows && fixedRows.length) {
      fixedRows.forEach((singleRow, idx) => {
        res.splice(
          singleRow.index,
          0,
          singleRow.highlighted ? (
            <CustomRow
              role="row"
              key={singleRow.id + idx}
              onClick={singleRow.onClick}
            >
              {singleRow.columns && singleRow.columns.length
                ? singleRow.columns.map((singleColumn, cIdx) => (
                    <td
                      role="cell"
                      key={singleColumn.id + cIdx}
                      onClick={singleColumn.onClick}
                      style={singleColumn.style}
                      className={
                        singleColumn.sticky
                          ? `sticky-cell sticky-cell--${singleColumn.sticky}`
                          : undefined
                      }
                    >
                      {singleColumn.value}
                    </td>
                  ))
                : null}
            </CustomRow>
          ) : (
            <tr role="row" key={singleRow.id + idx} onClick={singleRow.onClick}>
              {singleRow.columns && singleRow.columns.length
                ? singleRow.columns.map((singleColumn, cIdx) => (
                    <td
                      role="cell"
                      key={singleColumn.id + cIdx}
                      onClick={singleColumn.onClick}
                      style={singleColumn.style}
                    >
                      {singleColumn.value}
                    </td>
                  ))
                : null}
            </tr>
          )
        );
      });
    }

    return res;
  }, [page, prepareRow, fixedRows]);

  return (
    <Fragment>
      <StyledWrapper
        $topFlat={topFlat}
        $bottomFlat={bottomFlat}
        $spacing={{
          mb: "20px",
        }}
        {...wrapperOptions}
      >
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.headerClass,
                        style: {
                          minWidth: column.minWidth ? column.minWidth : "",
                          width: column.customWidth ? column.customWidth : "",
                        },
                      },
                      column.getSortByToggleProps(),
                    ])}
                  >
                    <TableHeader>
                      {column.render("Header")}
                      {column.canSort ? (
                        <TableHeaderSort>
                          <TableHeaderSortIco
                            $active={column.isSorted && !column.isSortedDesc}
                          >
                            ▲
                          </TableHeaderSortIco>
                          <TableHeaderSortIco
                            $active={column.isSorted && column.isSortedDesc}
                          >
                            ▼
                          </TableHeaderSortIco>
                        </TableHeaderSort>
                      ) : null}
                      {column.canFilter ? column.render("Filter") : null}
                    </TableHeader>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data && data.length ? (
            <tbody {...getTableBodyProps()}>{renderPage}</tbody>
          ) : null}
        </table>
      </StyledWrapper>
      {data && data.length ? (
        <Pagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          dataSize={data.length}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Fragment>
  );
};

export default Table;
