import { reinitializeIntercom } from "../actions/auth";
import {
  USER_LOGIN,
  UPDATE_LOGGED_USER,
  USER_LOGOUT,
  UPDATE_USER_SWITCHER,
} from "../types";

const initialState = {
  token: null,
  user: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN: {
      return {
        token: payload.token && payload.user ? payload.token : null,
        user:
          payload.token && payload.user
            ? {
                ...payload.user,
                photo: payload.user.photo
                  ? payload.user.photo +
                    `?v=${Math.floor(Math.random() * 10 + 1)}`
                  : null,
              }
            : {},
      };
    }
    case UPDATE_LOGGED_USER: {
      return {
        ...state,
        user: payload
          ? {
              ...payload,
              photo: payload.photo
                ? payload.photo + `?v=${Math.floor(Math.random() * 10 + 1)}`
                : null,
            }
          : state.user,
      };
    }
    case USER_LOGOUT: {
      return {
        ...state,
        token: null,
        user: {},
      };
    }
    case UPDATE_USER_SWITCHER: {
      const user = {
        ...state.user,
        ...payload,
      };

      reinitializeIntercom(user);

      return {
        ...state,
        user,
      };
    }
    default:
      return state;
  }
}
