import React, { Fragment } from "react";
import Checkbox from "../../UI/Checkbox";

const TypeStep = ({ onChange, type }) => {
  return (
    <Fragment>
      <p className="text-center">How would you like to add your users?</p>
      <Checkbox
        isChecked={type === "manual"}
        type="radio"
        look="fullWidth"
        onChange={onChange}
        name="type"
        value="manual"
        id="user-creation-manual"
        label="Add Users Manually"
        extraInfo="Manually add user information"
        $size="large"
      />
      <Checkbox
        isChecked={type === "csv"}
        type="radio"
        look="fullWidth"
        onChange={onChange}
        name="type"
        value="csv"
        id="user-creation-csv"
        label="Import CSV"
        extraInfo="Upload your .csv file"
        $size="large"
      />
    </Fragment>
  );
};

export default TypeStep;
