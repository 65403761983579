// SCHOOLS
export const FETCH_ALL_SCHOOLS = "FETCH_ALL_SCHOOLS";
export const SET_SINGLE_SCHOOL = "SET_SINGLE_SCHOOL";
export const UPDATE_SINGLE_SCHOOL = "UPDATE_SINGLE_SCHOOL";
export const DELETE_SINGLE_SCHOOL = "DELETE_SINGLE_SCHOOL";
export const UPDATE_USER_SWITCHER = "UPDATE_USER_SWITCHER";

// CLASSES
export const FETCH_CLASSES = "FETCH_CLASSES";
export const UPDATE_SINGLE_CLASS = "UPDATE_SINGLE_CLASS";
export const DELETE_SINGLE_CLASS = "DELETE_SINGLE_CLASS";

// ROLES
export const FETCH_ALL_ROLES = "FETCH_ALL_ROLES";
export const UPDATE_SINGLE_ROLE = "UPDATE_SINGLE_ROLE";
export const DELETE_SINGLE_ROLE = "DELETE_SINGLE_ROLE";

// DISTRICTS
export const FETCH_DISTRICTS = "FETCH_DISTRICTS";
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const DELETE_DISTRICT = "DELETE_DISTRICT";
export const RESET_DISTRICT_DATA = "RESET_DISTRICT_DATA";

// TAGS
export const FETCH_TAGS = "FETCH_TAGS";
export const DELETE_SINGLE_TAG = "DELETE_SINGLE_TAG";

// QUICK LINKS
export const OPEN_CONFIRMATOR = "OPEN_CONFIRMATOR";
export const UPDATE_CONFIRMATOR = "UPDATE_CONFIRMATOR";
export const CLOSE_CONFIRMATOR = "CLOSE_CONFIRMATOR";

export const OPEN_USER_CREATION = "OPEN_USER_CREATION";
export const CLOSE_USER_CREATION = "CLOSE_USER_CREATION";

// TERMS
export const FETCH_TERMS = "FETCH_TERMS";
export const UPDATE_SINGLE_TERM = "UPDATE_SINGLE_TERM";
export const DELETE_SINGLE_TERM = "DELETE_SINGLE_TERM";

// STRUCTURES
export const FETCH_ROLES_STRUCTURE = "FETCH_ROLES_STRUCTURE";
export const FETCH_CLASS_STRUCTURE = "FETCH_CLASS_STRUCTURE";
export const FETCH_DISTRICT_STRUCTURE = "FETCH_DISTRICT_STRUCTURE";

// MESSAGE
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_ANNOUNCEMENTS = "FETCH_ANNOUNCEMENTS";
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const UPDATE_ANNOUNCEMENT = "UPDATE_ANNOUNCEMENT";
export const OPEN_MESSAGING = "OPEN_MESSAGING";
export const CLOSE_MESSAGING = "CLOSE_MESSAGING";

// CLASS CODE MODAL
export const OPEN_CLASS_CODE_MODAL = "OPEN_CLASS_CODE_MODAL";
export const CLOSE_CLASS_CODE_MODAL = "CLOSE_CLASS_CODE_MODAL";

// USERS
export const USER_LOGIN = "USER_LOGIN";
export const UPDATE_LOGGED_USER = "UPDATE_LOGGED_USER";
export const USER_LOGOUT = "USER_LOGOUT";
export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const DELETE_SINGLE_USER = "DELETE_SINGLE_USER";
export const SET_USERS_FILTERS = "SET_USERS_FILTERS";

// USER PROFILE
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";

// USERS ANECDOTALS
export const FETCH_USER_ANECDOTALS = "FETCH_USER_ANECDOTALS";
export const UPDATE_USER_ANECDOTAL = "UPDATE_USER_ANECDOTAL";
export const DELETE_USER_ANECDOTAL = "DELETE_USER_ANECDOTAL";

// USERS IEPS
export const FETCH_USER_IEPS = "FETCH_USER_IEPS";
export const UPDATE_USER_IEP = "UPDATE_USER_IEP";
export const DELETE_USER_IEP = "DELETE_USER_IEP";

// USERS TAGS
export const FETCH_USER_TAGS = "FETCH_USER_TAGS";
export const DELETE_USER_TAG = "DELETE_USER_TAG";

// USERS CLASSES
export const FETCH_USER_CLASSES = "FETCH_USER_CLASSES";

// USERS GRADES
export const FETCH_USER_GRADES = "FETCH_USER_GRADES";

// USER INTEGRATIONS
export const FETCH_USER_INTEGRATIONS = "FETCH_USER_INTEGRATIONS";

// DISTRICT SETTINGS
export const FETCH_DISTRICT_INFO = "FETCH_DISTRICT_INFO";
export const UPDATE_DISTRICT_SETTINGS = "UPDATE_DISTRICT_SETTINGS";

// DISTRICT COMMENTS
export const FETCH_DISTRICT_COMMENTS = "FETCH_DISTRICT_COMMENTS";
export const UPDATE_DISTRICT_COMMENT = "UPDATE_DISTRICT_COMMENT";
export const DELETE_DISTRICT_COMMENT = "DELETE_DISTRICT_COMMENT";

// DISTRICT ANECDOTALS
export const FETCH_DISTRICT_ANECDOTALS_TYPES =
  "FETCH_DISTRICT_ANECDOTALS_TYPES";
export const UPDATE_DISTRICT_ANECDOTALS_TYPE =
  "UPDATE_DISTRICT_ANECDOTALS_TYPE";
export const DELETE_DISTRICT_ANECDOTALS_TYPE =
  "DELETE_DISTRICT_ANECDOTALS_TYPE";

// DISTRICT INTEGRATIONS
export const FETCH_DISTRICT_INTEGRATIONS = "FETCH_DISTRICT_INTEGRATIONS";

// DISTRICT GRADEBOOK
export const FETCH_DISTRICT_GRADEBOOKS = "FETCH_DISTRICT_GRADEBOOKS";
export const UPDATE_DISTRICT_GRADEBOOK = "UPDATE_DISTRICT_GRADEBOOK";
export const DELETE_DISTRICT_GRADEBOOK = "DELETE_DISTRICT_GRADEBOOK";

// COURSES
export const FETCH_COURSES = "FETCH_COURSES";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
