import {
  FETCH_DISTRICTS,
  UPDATE_DISTRICT,
  DELETE_DISTRICT,
  FETCH_DISTRICT_INFO,
  UPDATE_DISTRICT_SETTINGS,
  FETCH_DISTRICT_INTEGRATIONS,
  RESET_DISTRICT_DATA,
} from "../../types";

import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const resetDistrictDataAction = () => (dispatch) =>
  dispatch({
    type: RESET_DISTRICT_DATA,
  });

export const fetchAllDistrictsAction = () => async (dispatch) => {
  try {
    const res = await axios.get("/districts", config);

    dispatch({
      type: FETCH_DISTRICTS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const fetchSingleDistrictAction = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/districts/${id}`, config);

    dispatch({
      type: FETCH_DISTRICT_INFO,
      payload: {
        ...res.data,
      },
    });

    return res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error ocurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const createDistrictAction = async (data) => {
  const body = JSON.stringify({ ...data });

  try {
    await axios.post("/districts", body, config);
  } catch (err) {
    if (err.response) {
      throw new Error("Could not create district. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateDistrictAction = (data) => async (dispatch) => {
  const body = JSON.stringify({ ...data });

  try {
    const res = await axios.put(`/districts/${data.id}`, body, config);
    dispatch({
      type: UPDATE_DISTRICT,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      throw new Error(
        "Could not update the district. Please try again later.."
      );
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const deleteDistrictAction = (id) => async (dispatch) => {
  try {
    await axios.delete(`/districts/${id}`, config);
    dispatch({
      type: DELETE_DISTRICT,
      payload: id,
    });
  } catch (err) {
    if (err.response) {
      throw new Error(
        "Could not delete the district. Please try again later.."
      );
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const updateDisSettingsAction = (disId, data) => async (dispatch) => {
  const body = JSON.stringify({ ...data });

  try {
    await axios.put(`/districts/${disId}/settings`, body, config);
    dispatch({
      type: UPDATE_DISTRICT_SETTINGS,
      payload: data,
    });
  } catch (err) {
    if (err.response) {
      throw new Error(
        "Could not update the district. Please try again later.."
      );
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const fetchDisIntegrationsAction = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/districts/${id}/integrations`, config);

    dispatch({
      type: FETCH_DISTRICT_INTEGRATIONS,
      payload: {
        disId: parseInt(id, 10),
        data: res.data,
      },
    });

    return res && res.data;
  } catch (err) {
    if (err.response) {
      throw new Error("An error ocurred. Please try again later..");
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const createDisIntegrationAction = async (disId, data) => {
  const body = JSON.stringify(data);

  try {
    const res = await axios.post(
      `/districts/${disId}/integrations`,
      body,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const deleteDisIntegrationAction = async (disId, name) => {
  try {
    const res = await axios.delete(
      `/districts/${disId}/integrations/${name}`,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const syncDisGWorkspace = async (id) => {
  try {
    const res = await axios.get(
      `/districts/${id}/integrations/oauth/googleReportsApi`,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};

export const storeDisGWorkspace = async (id, code) => {
  try {
    const body = JSON.stringify({ code });

    const res = await axios.post(
      `/districts/${id}/integrations/oauth/googleReportsApi`,
      body,
      config
    );

    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        let error = {
          message: "Not Authorized",
          status: 403,
        };
        throw error;
      } else {
        throw new Error("An error occurred. Please try again later..");
      }
    } else {
      throw new Error(
        "Network error - Please check your network connection or firewall settings and try again."
      );
    }
  }
};
