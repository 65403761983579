import React from "react";
import StyledWrapper from "./StyledWrapper";

import { Spin } from "antd";

const Spinner = (props) => {
  return (
    <StyledWrapper {...props}>
      <Spin />
    </StyledWrapper>
  );
};

export default Spinner;
