import React from "react";
import { connect } from "react-redux";
import { closeConfirmatorAction } from "../../redux/actions/links";

import Modal from "../UI/Modal";
import Button from "../UI/Button";
import HorizontalSpace from "../UI/HorizontalSpace";

export const RawConfirmModal = ({
  data: {
    title,
    text,
    onConfirm,
    onConfirmText,
    onConfirmType,
    hideCancel,
    onCancel,
    onCancelText,
    onCancelType,
    loading,
  },
  closeConfirmatorAction,
}) => {
  return (
    <Modal
      title={title ? title : "Confirm"}
      body={
        <p className="text-center">
          {text ? text : "Are you sure you want to make this action?"}
        </p>
      }
      footer={
        <HorizontalSpace>
          {!hideCancel && (onCancel || closeConfirmatorAction) ? (
            <Button
              type={onCancelType ? onCancelType : undefined}
              htmlType="button"
              onClick={onCancel || closeConfirmatorAction}
              isLoading={loading}
            >
              {onCancelText ? onCancelText : "Cancel"}
            </Button>
          ) : null}

          {onConfirm ? (
            <Button
              type={onConfirmType ? onConfirmType : "warning"}
              htmlType="button"
              onClick={onConfirm}
              isLoading={loading}
            >
              {onConfirmText ? onConfirmText : "Save"}
            </Button>
          ) : null}
        </HorizontalSpace>
      }
      closeHandler={closeConfirmatorAction}
    />
  );
};

export default connect(
  (state) => ({
    data: state.links.confirmator.data,
  }),
  { closeConfirmatorAction }
)(RawConfirmModal);
